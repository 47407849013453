import { Dashboard } from '../models/board.model';

export const PROC_EXEC_DASHBOARD_ID = 'process-execution';
export const GOAL_OV_DASHBOARD_ID = 'goal-overview';
export const KPI_OV_DASHBOARD_ID = 'kpi-overview';
export const INSP_EXEC_DASHBOARD_ID = 'inspection-execution';

export const executionDashboard: Readonly<Dashboard> = JSON.parse(`
{
    "id": "${PROC_EXEC_DASHBOARD_ID}",
    "plantId": null,
    "tenantId": "6058628959b2575057b24b4d",
    "tenantName": "zerodefects",
    "loaded": true,
    "title": "",
    "userId": "user-2",
    "type": "default",
    "widgets": [
        {
            "instanceId": "6059da813b7d4c446dd5caec",
            "type": "DOCUMENT",
            "gridsterData": {
                "x": 25,
                "y": 13,
                "rows": 3,
                "cols": 7
            },
            "values": {
                "tableViewMode": false,
                "instanceId": 2,
                "boardControlled": true,
                "documentList": "",
                "viewType": "LAST_INPUT",
                "canAttach":true,
                "stepId": null,
                "title": {
                    "translations": {
                        "de": {
                            "text": "Dokumente Widget",
                            "translated": true
                        },
                        "en": {
                            "text": "Document Widget",
                            "translated": true
                        }
                    }
                },
                "order": null
            }
        },
        {
            "instanceId": "60802fc57d8449c8a71cb2b1",
            "type": "CHARACTERISTIC_NOTES",
            "gridsterData": {
                "x": 25,
                "y": 7,
                "rows": 6,
                "cols": 7
            },
            "values": {
              "instanceId": 2,
              "charId": null,
              "showTitle": false,
              "viewType": "LAST_INPUTTED",
              "canAttach":true,
              "title": {
                "translations": {
                  "de": {
                    "text": "Charakteristische Hinweise Widget",
                    "translated": true
                  },
                  "en": {
                    "text": "Characteristic Notes Widget",
                    "translated": true
                  }
                }
              },
              "order": null,
              "diagramStep": null
            }
        },
        {
            "instanceId": "60781cb5e1e7d1f242a67921",
            "type": "TASK",
            "gridsterData": {
                "x": 25,
                "y": 3,
                "rows": 4,
                "cols": 7
            },
            "values": {
              "responsiblePerson": null,
              "instanceId": 2,
              "refreshInterval": "525600",
              "boardControlled": true,
              "title": {
                "translations": {
                  "de": {
                    "text": "Aufgabe Widget",
                    "translated": true
                  },
                  "en": {
                    "text": "Task Widget",
                    "translated": true
                  }
                }
              },
              "myTasks": false,
              "status": ""
            }
        },
        {
            "instanceId": "6059da94bbf96df7dbe19ac9",
            "type": "CHARACTERISTIC_NOTES",
            "gridsterData": {
                "x": 9,
                "y": 2,
                "rows": 6,
                "cols": 16
            },
            "values": {
                "instanceId": 2,
                "charId": null,
                "viewType": "SELECTED",
                "title": {
                    "translations": {
                        "de": {
                            "text": "Charakteristische Hinweise Widget",
                            "translated": true
                        },
                        "en": {
                            "text": "Characteristic Notes Widget",
                            "translated": true
                        }
                    }
                },
                "order": null,
                "diagramStep": null
            }
        },
        {
            "instanceId": "6059dac554848787e04ad66b",
            "type": "CHARACTERISTIC_OVERVIEW",
            "gridsterData": {
                "x": 0,
                "y": 2,
                "rows": 14,
                "cols": 9
            },
            "values": {
                "filter": "all",
                "instanceId": 2,
                "ignoredCharacteristicIdentifiers": [],
                "boardControlled": true,
                "char-list": [],
                "chartType": "line",
                "title": {
                    "translations": {
                        "de": {
                            "text": "Parameterübersicht",
                            "translated": true
                        },
                        "en": {
                            "text": "Parameter Overview",
                            "translated": true
                        }
                    }
                },
                "direction": "horizontal"
            }
        },
        {
            "instanceId": "6059db193a05f5caf66c6165",
            "type": "INPUT_CHARACTERISTIC_VALUE",
            "gridsterData": {
                "x": 9,
                "y": 8,
                "rows": 8,
                "cols": 16
            },
            "values": {
                "title": {
                    "translations": {
                        "de": {
                            "text": "Messwerteingabe Widget",
                            "translated": true
                        },
                        "en": {
                            "text": "Input Characteristic Value Widget",
                            "translated": true
                        }
                    }
                }
            }
        },
        {
            "instanceId": "6059f0dfe6c8a5d9190e310d",
            "type": "STEP_DISPLAYER",
            "gridsterData": {
                "x": 0,
                "y": 0,
                "rows": 2,
                "cols": 25
            },
            "values": {
                "instanceId": 2,
                "boardControlled": true,
                "diagramId": null,
                "title": {
                    "translations": {
                        "de": {
                            "text": "Produktschritte",
                            "translated": true
                        },
                        "en": {
                            "text": "Product Steps",
                            "translated": true
                        }
                    }
                }
            }
        },
        {
            "instanceId": "605c503b630938cad998fd4b",
            "type": "PERSON",
            "gridsterData": {
                "x": 25,
                "y": 0,
                "rows": 3,
                "cols": 7
            },
            "values": {
                "persons": null,
                "instanceId": 2,
                "boardControlled": true,
                "productData": false,
                "title": {
                    "translations": {
                        "de": {
                            "text": "Person Widget",
                            "translated": true
                        },
                        "en": {
                            "text": "Person Widget",
                            "translated": true
                        }
                    }
                },
                "order": null
            }
        }
    ],
    "productDiagramId": null,
    "partId": null,
    "shared": true,
    "isDefault": false,
    "gridMargin": 8,
    "createdBy": "admin",
    "lastModifiedBy": "admin"
}
`);

export const goalOverviewDashboard: Readonly<Dashboard> = JSON.parse(`
    {
        "id":"${GOAL_OV_DASHBOARD_ID}",
        "loaded": true,
        "plantId":null,
        "title":"",
        "description":"",
        "type":"default",
        "widgets":[
          {
            "instanceId":"60ac99e31fbab1a7c98c44ba",
            "type":"GOAL_OVERVIEW",
            "gridsterData":{
               "x":0,
               "y":5,
               "rows":11,
               "cols":32
            },
            "values":{
               "showTitle":false,
               "title":{
                  "translations":{
                     "de":{
                        "text":"",
                        "translated":true
                     },
                     "en":{
                        "text":"",
                        "translated":true
                     }
                  }
               }
            }
         },
          {
              "instanceId":"60ae1bbf2f3c202dd8d7e00c",
              "type":"STEP_DISPLAYER",
              "gridsterData":{
                 "x":0,
                 "y":0,
                 "rows":2,
                 "cols":15
              },
              "values":{
                 "instanceId":2,
                 "boardControlled":true,
                 "diagramId":null,
                 "showTitle":false,
                 "title":{
                    "translations":{
                       "de":{
                          "text":"Prozessschritte",
                          "translated":true
                       },
                       "en":{
                          "text":"Process steps",
                          "translated":true
                       }
                    }
                 }
              }
           },
           {
              "instanceId":"60b5fbd5203fa85c9b4d4728",
              "type":"INSPECTION_PROGRESS",
              "gridsterData":{
                 "x":26,
                 "y":2,
                 "rows":3,
                 "cols":6
              },
              "values":{
                 "showTitle":false,
                 "displayProgress":true,
                 "displayConfidence":true,
                 "title":{
                    "translations":{
                       "de":{
                          "text":"Ergebnisübersicht",
                          "translated":true
                       },
                       "en":{
                          "text":"Key Result Overview",
                          "translated":true
                       }
                    }
                 }
              }
           },
           {
            "instanceId":"60b788d3242f14ae6de34a7c",
            "type":"PERSON",
            "gridsterData":{
               "x":14,
               "y":2,
               "rows":3,
               "cols":12
            },
            "values":{
               "persons":null,
               "instanceId":2,
               "boardControlled":true,
               "showTitle":false,
               "productData":false,
               "title":{
                  "translations":{
                     "de":{
                        "text":"Verantwortlicher",
                        "translated":true
                     },
                     "en":{
                        "text":"Responsible",
                        "translated":true
                     }
                  }
               },
               "order":null
            }
         },
          {
              "instanceId":"60b63b78e3c2ebcfc722b9f0",
              "type":"CHARACTERISTIC_NOTES",
              "gridsterData":{
                 "x":0,
                 "y":2,
                 "rows":3,
                 "cols":7
              },
              "values":{
                 "instanceId":2,
                 "charId":null,
                 "showTitle":false,
                 "viewType":"GOAL_OBJECTIVE",
                 "title":{
                    "translations":{
                       "de":{
                          "text":"",
                          "translated":true
                       },
                       "en":{
                          "text":"",
                          "translated":true
                       }
                    }
                 },
                 "order":null,
                 "diagramStep":null
              }
           },
           {
              "instanceId":"60b75cca6f10ac282970af45",
              "type":"CHARACTERISTIC_NOTES",
              "gridsterData":{
                 "x":7,
                 "y":2,
                 "rows":3,
                 "cols":7
              },
              "values":{
                 "instanceId":2,
                 "charId":null,
                 "showTitle":false,
                 "viewType":"GOAL_REASON",
                 "title":{
                    "translations":{
                       "de":{
                          "text":"",
                          "translated":true
                       },
                       "en":{
                          "text":"",
                          "translated":true
                       }
                    }
                 },
                 "order":null,
                 "diagramStep":null
              }
           },
           {
            "instanceId":"60b63b78e3c2ebcfc722b9f0",
            "type":"ALIGNMENT_DISPLAY",
            "gridsterData":{
              "x":15,
              "y":0,
              "rows":2,
              "cols":8
            },
            "values":{
               "instanceId":2,
               "showTitle":false,
               "viewType":"CHILD_ENTITY",
               "title":{
                  "translations":{
                     "de":{
                        "text":"Alignment",
                        "translated":true
                     },
                     "en":{
                      "text":"Alignment",
                      "translated":true
                     }
                  }
               }
            }
         },
         {
          "instanceId":"60b63b78e3c2ebcfc722b9f0",
          "type":"ALIGNMENT_DISPLAY",
          "gridsterData":{
            "x":23,
            "y":0,
            "rows":2,
            "cols":9
          },
          "values":{
             "instanceId":2,
             "showTitle":false,
             "viewType":"PARENT_ENTITY",
             "title":{
                "translations":{
                   "de":{
                    "text":"Alignment",
                    "translated":true
                   },
                   "en":{
                    "text":"Alignment",
                    "translated":true
                   }
                }
             }
          }
       }
        ],
        "isDefault":false,
        "gridMargin": 8
     }`);

export const kpiOverviewDashboard = JSON.parse(`
{
   "id" : "${KPI_OV_DASHBOARD_ID}",
   "plantId" : null,
   "tenantId" : "5f5a34eb41624a542a4bb7ad",
   "tenantName" : "zerodefects",
   "title" : "",
   "description" : "",
   "loaded": true,
   "type" : "default",
   "widgets" : [
    {
      "instanceId" : "60cb509bcdd0ff831b1f72c2",
      "type" : "STEP_DISPLAYER",
      "gridsterData" : {
        "x" : 0,
        "y" : 0,
        "rows" : 2,
        "cols" : 26
      },
      "values" : {
        "instanceId" : 2,
        "boardControlled" : true,
        "diagramId" : null,
        "showTitle" : false,
        "title" : {
          "translations" : {
            "de" : {
              "text" : "Prozessschritte",
              "translated" : true
            },
            "en" : {
              "text" : "Process steps",
              "translated" : true
            }
          }
        }
      }
    },
    {
     "instanceId" : "60cb4a5a233deca4b102d935",
     "type" : "CHARACTERISTIC_NOTES",
     "gridsterData" : {
       "x" : 8,
       "y" : 2,
       "rows" : 2,
       "cols" : 10
     },
     "values" : {
       "instanceId" : 2,
       "charId" : null,
       "showTitle" : false,
       "viewType" : "KPI_TIME_RANGE",
       "title" : {
         "translations" : {
           "de" : {
             "text" : "",
             "translated" : true
           },
           "en" : {
             "text" : "",
             "translated" : true
           }
         }
       },
       "order" : null,
       "diagramStep" : null
     }
   },
   {
     "instanceId" : "60cb518477513af622a21f1f",
     "type" : "PERSON",
     "gridsterData" : {
       "x" : 26,
       "y" : 0,
       "rows" : 5,
       "cols" : 6
     },
     "values" : {
       "persons" : null,
       "instanceId" : 2,
       "boardControlled" : true,
       "showTitle" : false,
       "productData" : false,
       "title" : {
         "translations" : {
           "de" : {
             "text" : "Verantwortlicher",
             "translated" : true
           },
           "en" : {
             "text" : "Responsible",
             "translated" : true
           }
         }
       },
       "order" : null
     }
   },
   {
     "instanceId" : "60d1cbe5f0ef2ac2033f082a",
     "type" : "KPI_OVERVIEW",
     "gridsterData" : {
       "x" : 0,
       "y" : 4,
       "rows" : 6,
       "cols" : 26
     },
     "values" : {
       "title" : {
         "translations" : {
           "de" : {
             "text" : "KPI Übersicht",
             "translated" : true
           },
           "en" : {
             "text" : "KPI Overview",
             "translated" : true
           }
         }
       }
     }
   },
   {
    "instanceId" : "60d309296e58eec2e14b6162",
    "type" : "INSPECTION_PROGRESS",
    "gridsterData" : {
      "x" : 18,
      "y" : 2,
      "rows" : 2,
      "cols" : 8
    },
    "values" : {
      "title" : {
        "translations" : {
          "de" : {
            "text" : "Ergebnisübersicht",
            "translated" : true
          },
          "en" : {
            "text" : "Key Result Overview",
            "translated" : true
          }
        }
      }
    }
  },
   {
     "instanceId" : "60d1ed2a1c11b2b182f890f8",
     "type" : "CHARACTERISTIC_NOTES",
     "gridsterData" : {
       "x" : 0,
       "y" : 2,
       "rows" : 2,
       "cols" : 8
     },
     "values" : {
       "instanceId" : 2,
       "charId" : null,
       "showTitle" : false,
       "viewType" : "KPI_DESCRIPTION",
       "title" : {
         "translations" : {
           "de" : {
             "text" : "",
             "translated" : true
           },
           "en" : {
             "text" : "",
             "translated" : true
           }
         }
       },
       "order" : null,
       "diagramStep" : null
     }
   },
   {
     "instanceId" : "60d1ed571f665865ddea7c60",
     "type" : "CHARACTERISTIC_NOTES",
     "gridsterData" : {
       "x" : 26,
       "y" : 9,
       "rows" : 5,
       "cols" : 6
     },
     "values" : {
       "instanceId" : 2,
       "charId" : null,
       "showTitle" : false,
       "viewType" : "LAST_INPUTTED",
       "title" : {
         "translations" : {
           "de" : {
             "text" : "",
             "translated" : true
           },
           "en" : {
             "text" : "",
             "translated" : true
           }
         }
       },
       "canAttach" : true,
       "order" : null,
       "diagramStep" : null
     }
   },
   {
    "instanceId" : "60d1ed85509be99162515e02",
    "type" : "TASK",
    "gridsterData" : {
      "x" : 26,
      "y" : 5,
      "rows" : 4,
      "cols" : 6
    },
    "values" : {
      "responsiblePerson" : null,
      "instanceId" : 2,
      "boardControlled" : true,
      "refreshInterval" : "525600",
      "showTitle" : false,
      "title" : {
        "translations" : {
          "de" : {
            "text" : "Aufgabenliste",
            "translated" : true
          },
          "en" : {
            "text" : "Task List",
            "translated" : true
          }
        }
      },
      "myTasks" : false,
      "status" : ""
    }
  },
   {
     "instanceId" : "60d33a0853ea5bc9b8abebff",
     "type" : "SINGLE_PARAM",
     "gridsterData" : {
       "x" : 0,
       "y" : 10,
       "rows" : 6,
       "cols" : 26
     },
     "values" : {
       "instanceId" : 2,
       "boardControlled" : true,
       "charId" : null,
       "showTitle" : false,
       "chartType" : "line",
       "title" : {
         "translations" : {
           "de" : {
             "text" : "Kennfeld",
             "translated" : true
           },
           "en" : {
             "text" : "Characteristic Chart",
             "translated" : true
           }
         }
       },
       "order" : null,
       "diagramStep" : null,
       "direction" : "horizontal"
     }
   },
   {
    "instanceId" : "60d1ed627aecbfffad630fae",
    "type" : "DOCUMENT",
    "gridsterData" : {
      "x" : 26,
      "y" : 14,
      "rows" : 2,
      "cols" : 6
    },
    "values" : {
      "tableViewMode" : false,
      "instanceId" : 2,
      "boardControlled" : true,
      "documentType" : "",
      "documentList" : "",
      "viewType" : "LAST_INPUT",
      "stepId" : null,
      "title" : {
        "translations" : {
          "de" : {
            "text" : "Dokumentenliste",
            "translated" : true
          },
          "en" : {
            "text" : "Document List",
            "translated" : true
          }
        }
      },
      "canAttach" : true,
      "order" : null
    }
  }
   ],
   "partId" : null,
   "shared" : false,
   "isDefault" : true,
   "gridMargin" : 8
 }`);

export const inspectionDashboard: Readonly<Dashboard> = JSON.parse(`
    {
        "id": "${INSP_EXEC_DASHBOARD_ID}",
        "plantId": null,
        "tenantId": "6058628959b2575057b24b4d",
        "tenantName": "zerodefects",
        "loaded": true,
        "title": "",
        "userId": "user-2",
        "type": "default",
        "widgets": [
          {
            "instanceId": "6059f0dfe6c8a5d9190e310d",
            "type": "STEP_DISPLAYER",
            "gridsterData": {
                "x": 0,
                "y": 0,
                "rows": 2,
                "cols": 32
            },
            "values": {
                "instanceId": 2,
                "boardControlled": true,
                "diagramId": null,
                "title": {
                    "translations": {
                        "de": {
                            "text": "Produktschritte",
                            "translated": true
                        },
                        "en": {
                            "text": "Product Steps",
                            "translated": true
                        }
                    }
                }
            }
        },
        {
            "instanceId": "6059dac554848787e04ad66b",
            "type": "CHARACTERISTIC_OVERVIEW",
            "gridsterData": {
              "x": 0,
              "y": 2,
              "rows": 8,
              "cols": 16
            },
            "values": {
                "filter": "all",
                "instanceId": 2,
                "ignoredCharacteristicIdentifiers": [],
                "boardControlled": true,
                "char-list": [],
                "chartType": "line",
                "title": {
                    "translations": {
                        "de": {
                            "text": "Parameterübersicht",
                            "translated": true
                        },
                        "en": {
                            "text": "Parameter Overview",
                            "translated": true
                        }
                    }
                },
                "inspectionFlow" : false,
                "LTL" : false,
                "type" : false,
                "UTL" : false,
                "sample" : true,
                "inspectionEquipment" : true,
                "inspected" : true,
                "showTitle" : false,
                "name" : true,
                "targetValue" : false,
                "liveValue" : true,
                "id" : true,
                "status" : false
            }
        },
        {
            "instanceId": "6059db193a05f5caf66c6165",
            "type": "INPUT_CHARACTERISTIC_VALUE",
            "gridsterData": {
                "x": 16,
                "y": 2,
                "rows": 8,
                "cols": 16
            },
            "values": {
                "title": {
                    "translations": {
                        "de": {
                            "text": "Messwerteingabe Widget",
                            "translated": true
                        },
                        "en": {
                            "text": "Input Characteristic Value Widget",
                            "translated": true
                        }
                    }
                }
            }
        },
        {
          "instanceId": "6059da813b7d4c446dd5caec",
          "type": "DOCUMENT",
          "gridsterData": {
              "x": 0,
              "y": 9,
              "rows": 5,
              "cols": 10
          },
          "values": {
              "tableViewMode": false,
              "instanceId": 2,
              "boardControlled": true,
              "documentList": "",
              "viewType": "LAST_INPUT",
              "canAttach":false,
              "stepId": null,
              "title": {
                  "translations": {
                      "de": {
                          "text": "Dokumente Widget",
                          "translated": true
                      },
                      "en": {
                          "text": "Document Widget",
                          "translated": true
                      }
                  }
              },
              "order": null
          }
      },
      {
          "instanceId": "60802fc57d8449c8a71cb2b1",
          "type": "CHARACTERISTIC_NOTES",
          "gridsterData": {
              "x": 10,
              "y": 9,
              "rows": 5,
              "cols": 10
          },
          "values": {
            "instanceId": 2,
            "charId": null,
            "showTitle": false,
            "viewType": "LAST_INPUTTED",
            "canAttach":true,
            "title": {
              "translations": {
                "de": {
                  "text": "Charakteristische Hinweise Widget",
                  "translated": true
                },
                "en": {
                  "text": "Characteristic Notes Widget",
                  "translated": true
                }
              }
            },
            "order": null,
            "diagramStep": null
          }
      },
      {
        "instanceId": "605c503b630938cad998fd4b",
        "type": "PERSON",
        "gridsterData": {
            "x": 20,
            "y": 9,
            "rows": 5,
            "cols": 6
        },
        "values": {
            "persons": null,
            "instanceId": 2,
            "boardControlled": true,
            "productData": false,
            "title": {
                "translations": {
                    "de": {
                        "text": "Person Widget",
                        "translated": true
                    },
                    "en": {
                        "text": "Person Widget",
                        "translated": true
                    }
                }
            },
            "order": null
        }
      },
      {
          "instanceId": "6059da94bbf96df7dbe19ac9",
          "type": "CHARACTERISTIC_NOTES",
          "gridsterData": {
              "x": 26,
              "y": 9,
              "rows": 5,
              "cols": 6
          },
          "values": {
              "instanceId": 2,
              "charId": null,
              "viewType": "SELECTED",
              "title": {
                  "translations": {
                      "de": {
                          "text": "Charakteristische Hinweise Widget",
                          "translated": true
                      },
                      "en": {
                          "text": "Characteristic Notes Widget",
                          "translated": true
                      }
                  }
              },
              "order": null,
              "diagramStep": null
          }
      }
    ],
    "productDiagramId": null,
    "partId": null,
    "shared": true,
    "isDefault": false,
    "gridMargin": 8,
    "createdBy": "admin",
    "lastModifiedBy": "admin"
}
`);

export const predefinedDashboards = [
  executionDashboard,
  goalOverviewDashboard,
  kpiOverviewDashboard,
  inspectionDashboard,
];
