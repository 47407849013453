import { Catalog } from 'app/main/content/apps/catalog/catalog/catalog.model';
import { CharacteristicStatus } from 'app/main/content/apps/designers/process/model/process-diagram.model';
import { CharacteristicType } from 'app/main/content/apps/designers/shared/step-requirements/model/characteristic-type.model';
import { DocumentInfo } from 'app/main/content/apps/file-manager/document.model';
import { Progress } from 'app/main/content/apps/result/inspection-result/instance-progress.model';
import { UserInfo } from 'app/shared';
import { Textstore } from 'app/shared/lang-dialog/textstore.model';

export enum CharacteristicOrigin {
  CONSTRUCTION = 'CONSTRUCTION',
  FMEA = 'FMEA',
  QS = 'QS',
  RECLAMATION = 'RECLAMATION',
  CAD = 'CAD',
  SFMEA = 'SFMEA',
  VIDI = 'VIDI',
}

export enum CharacteristicRadio {
  PROCESS = 'PROCESS',
  PRODUCT = 'PRODUCT',
}

export interface InspectionInterval {
  everyNthSample: number;
  interval: number;
  intervalInMinutes: number;
  timeType: boolean;
  unit: 'MINUTES';
}

export interface Characteristic {
  nr?: number;
  documentList?: DocumentInfo[];
  sampleData?: CharacteristicSample;
  id?: string;
  name?: Textstore;
  customId?: string;
  instruction?: Textstore;
  controlText?: Textstore;
  reactionText?: Textstore;
  reactionDocList?: DocumentInfo[];
  data?: CharacteristicType;
  responsible?: UserInfo;
  startDate?: Date;
  endDate?: Date;
  metadata?: CharacteristicMetadata;
  unitId?: string;
  unit?: Catalog;
  inspectionFlow?: CharacteristicInspectionFlow;
  sampleSize?: number;
  inspectionInterval?: InspectionInterval;
  weightingId?: string;
  checkPointIds?: string[];
  characteristicTypeId?: string;
  characteristicType?: Catalog;
  identifierId?: string;
  dimensionId?: string;
  identifier?: Catalog;
  weighting?: Catalog;
  checkPoints?: Catalog[];
  origin?: CharacteristicOrigin;
  dimension?: Catalog;
  radio?: CharacteristicRadio;
  inspectionText?: Textstore;
  zparam?: string;
}

export interface CharacteristicSample {
  id: string;
  processInstanceId: string;
  stepId: string;
  partId: string;
  characteristicId: string;
  progress: Progress;
  qmStatistic: QMStatistic;
  exceptionId: string;
  sampleNumber: number;
  indexInSample: number;
  status: CharacteristicStatus;
  exception: Catalog;
  defects: Catalog[];
  action: Catalog;
}

export interface QMStatistic {
  average: number;
  median: number;
  variance: number;
  populationVariance: number;
  range: number;
  stdev: number;
  cp: number;
  cpkMinimum: number;
  cpkLTL: number;
  cpkUTL: number;
  cpkReference: number;
  numberOfValues: number;
  minimum: number;
  maximum: number;
  numberOfErrors: number;
  lowerControlLimit: number;
  upperControlLimit: number;
  lowerToleranceLimit: number;
  upperToleranceLimit: number;
}

export abstract class CharacteristicMetadata {
  id: string;
}

export class InspectionCharacteristicMetadata extends CharacteristicMetadata {
  readonly _class = 'de.pickert.module.designer.domain.characteristicmetadata.InspectionCharacteristicMetadata';

  constructor(public characteristicInspectionEquipments?: CharacteristicInspectionEquipment[]) {
    super();
  }
}

export class KPICharacteristicMetadata extends CharacteristicMetadata {
  readonly _class = 'de.pickert.module.designer.domain.characteristicmetadata.InspectionCharacteristicMetadata';
}

export class GoalCharacteristicMetadata extends CharacteristicMetadata {
  readonly _class = 'de.pickert.module.designer.domain.characteristicmetadata.InspectionCharacteristicMetadata';
}

export class ProcessCharacteristicMetadata extends CharacteristicMetadata {
  readonly _class = 'de.pickert.module.designer.domain.characteristicmetadata.ProcessCharacteristicMetadata';
}

export enum CharacteristicInspectionFlow {
  /** Parallel Characteristic */
  PARALLEL = 'PARALLEL',
  /** Serial Characteristic */
  SERIAL = 'SERIAL',
  /** Text Divider */
  TEXT = 'TEXT',
  /** Block Divider */
  BLOCK = 'BLOCK',
  /** Multi-Measurement Characteristic -  Not implemented for now */
  MULTI_MEASUREMENT = 'MULTI_MEASUREMENT',
}

export interface CharacteristicInspectionEquipment {
  inspectionEquipmentId?: string;
  inspectionEquipmentGroup?: Catalog;
  inspectionEquipment?: Catalog;
  bridgeId?: string;
  bridge: Catalog;
  boxId?: string;
  channel: number;
  driverVariantId?: string;
}
