import { Action } from '@ngrx/store';
import { Characteristic, CharacteristicInspectionEquipment, CharacteristicSample } from 'app/core/components/characteristic/characteristic.model';
import { CommentData } from 'app/core/components/comments/comment.model';
import { IRestError, RestError } from 'app/core/models/rest.model';
import { Catalog } from 'app/main/content/apps/catalog/catalog/catalog.model';
import { DiagramStep, Sample } from 'app/main/content/apps/designers/process/model/process-diagram.model';
import { DocumentInfo, DocumentStore } from 'app/main/content/apps/file-manager/document.model';
import { ProcessInstanceDTO, StepInstanceDTO } from 'app/main/content/apps/result/inspection-result/instance-progress.model';
import { ProcessInstance, InspectionStatus, SyncStatus } from 'app/main/content/apps/result/process-result/process-instance.model';
import { ActionWithPayload, User } from 'app/shared';
import { CharacteristicHistory, ZeroMessage } from '../../models/message.model';
import { Complaint } from '../../../../complaint/complaint.model';
import { SampleRequiredPropertyKeyType } from 'app/main/content/apps/designers/shared/step-requirements/model/sample-requirement-property-key-type';
import { CharacteristicValueStatus } from '../../../widgets/input-characteristic-value/input-characteristic-constants';
export const INIT_SEARCH_DATA_FROM_USER_DATA = '[Dashboard On Init] Load data from the user.';

export const CLEAR_DASHBOARD_HEADER_SEARCH_CRITERIA = '[Dashboard Search Header clear button] Clear search criteria';
export const RESET_CRITERIA_ON_ROUTE = '[Dashboard Parent route change] Clear search criteria';

export const TRY_LOAD_STEP_AFTER_COMPLETED_STEP =
  '[Dashboard effects] Load step after the last one, when the loaded step last message contains value for the last characteristic.';
export const TRY_LOAD_STEP_BY_ID = '[DASHSE] Try load step by id';
export const TRYING_TO_LOAD_THE_SAME_STEP = '[Dashboard Search Effects] Attempting to load the loaded step.';
export const LOAD_STEP_BY_ID_SUCCESS = '[Dashboard Search API Response] Load step by id success';
export const LOAD_STEP_BY_ID_ERROR = '[Dashboard Search API Response] Load step by id error';
export const NO_STEP_ID_TO_LOAD = '[Dashboard Search Effects] Skip loading step by id, because stepId is missing.';
export const STEP_FOR_LOAD_NOT_IN_DIAGRAM = '[Dashboard Search Effects] Skip loading step by id, because there is no such step in diagram.';

export const TRY_LOAD_NEXT_STEP = '[Dashboard Effects Sample Widget Step Finished] Try load step next diagram step';
export const NEXT_LOAD_STEP_IS_SAME =
  '[Dashboard Effects] Next step for loading is the same as previous. Should be investigated as an likely error. Skipping the loading action.';
export const NO_NEXT_STEP_TO_LOAD = "[Dashboard Effects] Next step to load doesn't exist. Skipping the loading action.";
export const LOAD_NEXT_STEP_SUCCESS = '[Dashboard Search API Response] Load step next diagram step success';
export const LOAD_NEXT_STEP_ERROR = '[Dashboard Search API Response] Load step next diagram step error';

export const TRY_SUBMIT_CHAR_VALUE_ZERO_MESSAGE = '[Dashboard characteristic input widget form submit] Try submit zero message.';
export const ZERO_MESSAGE_CHAR_VALUE_SUBMIT_SUCCESS = '[Dashboard characteristic API Response] Submit zero message success.';
export const ZERO_MESSAGE_CHAR_VALUE_SUBMIT_ERROR = '[Dashboard characteristic API Response] Submit zero message error.';
export const TRY_SEND_MAIL_ON_TOLERANCE_VIOLATION = '[Dashboard Tolerance Violation Form] Send mail on tolerance violation.';
export const SEND_MAIL_ON_TOLERANCE_VIOLATION_SUCCESS = '[Dashboard Tolerance Violation Form] Send mail on tolerance violation success.';
export const SEND_MAIL_ON_TOLERANCE_VIOLATION_ERROR = '[Dashboard Tolerance Violation Form] Send mail on tolerance violation  error.';
export const INSTANCE_IS_ALREADY_LOCKED_BY_ANOTHER_USER = '[Dashboard Search Effects] Cannot lock order before sending message.';

export const ZERO_MESSAGE_CHAR_VALUE_CHAR_IS_SAME = '[Dashboard API Response] Next characteristic is the same.';
export const ZERO_MESSAGE_CHAR_VALUE_ALL_VALUES_ENTERED = '[Dashboard API] Next characteristic is null. All values were entered';

export const COMMENT_ZERO_MESSAGE_SUBMIT = '[Dashboard characteristic characteristic note widget comment submit] Send comment data.';
export const SELECT_NEXT_CHARACTERISTIC = '[Dashboard select next characteristic widget] Select next characteristic.';
export const SELECT_PREVIOUS_CHARACTERISTIC = '[Dashboard select previous characteristic widget] Select previous characteristic.';

export const TRY_LOAD_CRITERIA_SEARCH_DATA = '[Task manager source navigate] Try load search criteria.';
export const LOAD_CRITERIA_SEARCH_DATA_SUCCESS = '[Dashboard Search API Response] Load search criteria success.';
export const LOAD_CRITERIA_SEARCH_DATA_ERROR = '[Dashboard Search API Response] Load search criteria error.';
export const LOADED_CRITERIA_DATA_IS_COMPLETE = '[Dashboard Effects Criteria contains all fields] Criteria contains all fields. No extra data to be loaded.';

export const UPDATE_INPUTTED_DOCUMENTS_FOR_PARAMETER_DOCUMENTS_WIDGET = '[Dashboard Document widget input documents] Update inputted documents for parameter.';
export const UPDATE_INPUTTED_DOCUMENTS_FOR_PARAMETER_INPUT_CHARACTERISTIC = '[Dashboard Characteristic widget input documents] Update inputted documents for parameter.';

export const UPDATE_INPUTTED_NOTE_FOR_PARAMETER_CHARACTERISTIC_NOTE_WIDGET = '[Dashboard Characteristic note widget input note] Update inputted note for parameter.';
export const UPDATE_INPUTTED_NOTE_FOR_PARAMETER_INPUT_CHARACTERISTIC = '[Dashboard Characteristic widget input note] Update inputted note for parameter.';

export const TRY_UPDATE_ACTION_CATALOG_FOR_CHARACTERISTIC_IN_SAMPLE =
  '[Dashboard characteristic input widget action catalog] Update inputted action catalog for characteristic in sample.';
export const UPDATE_ACTION_CATALOG_FOR_CHARACTERISTIC_IN_SAMPLE_SUCCESS =
  '[Dashboard characteristic input widget action catalog] Update inputted action catalog for characteristic in sample success.';
export const UPDATE_ACTION_CATALOG_FOR_CHARACTERISTIC_IN_SAMPLE_ERROR =
  '[Dashboard characteristic input widget action catalog] Update inputted action catalog for characteristic in sample error.';

export const TRY_UPDATE_INTERNAL_COMPLAINT_FOR_SAMPLE = '[Dashboard characteristic input widget complaint] Update inputted internal complaint for sample.';
export const UPDATE_INTERNAL_COMPLAINT_FOR_SAMPLE_SUCCESS = '[Dashboard characteristic input widget complaint] Update inputted internal complaint for sample success.';
export const UPDATE_INTERNAL_COMPLAINT_FOR_SAMPLE_ERROR = '[Dashboard characteristic input widget complaint] Update inputted internal complaint for sample error.';

export const NAVIGATED_TO_DASHBOARD = '[Dashboard navigation] Navigated to a dashboard.';
export const FINISH_CRITERIA_UPDATE = '[Dashboard Search Effects] All of the criteria fields have been set.';

export const TRY_LOAD_MISSING_STEP_FROM_CRITERIA = '[Dashboard effects criteria change] Search criteria is missing step and has diagram and instance.';

export const TRY_GET_CHARACTERISTIC_HISTORY = '[Dashboard Search Effects Characteristic Selection change] Try load characteristic history';
export const SKIP_LOADING_CHARACTERISTIC_HISTORY = '[Characteristic Select Board Search Effects no instance or characteristic] Skip load characteristic history';
export const GET_CHARACTERISTIC_HISTORY_SUCCESS = '[API Response] Load characteristic history success';
export const GET_CHARACTERISTIC_HISTORY_ERROR = '[API Response] Load characteristic history error';

export const TRY_GET_CHARACTERISTIC_SAMPLE_DATA = '[Dashboard Search Effects Characteristic Selection change] Try load characteristic sample data';
export const GET_CHARACTERISTIC_SAMPLE_DATA_SUCCESS = '[Dashboard Search API Response] Load characteristic sample data success';
export const GET_CHARACTERISTIC_SAMPLE_DATA_ERROR = '[Dashboard Search API Response] Load characteristic sample data error';

export const TRY_SELECT_LOADED_STEP_CHARACTERISTIC = '[Dashboard Effects Initial criteria change] Try load the latest instance history';
export const SELECT_LOADED_STEP_CHARACTERISTIC_SUCCESS = '[API Response] Load the latest instance history success';
export const SELECT_LOADED_STEP_CHARACTERISTIC_ERROR = '[API Response] Load the latest instance history error';

export const RECIEVED_CHARACTERISTIC_HISTORY_ENTRY = '[Dashboard Search Effects Socket Response] Handle recieved char history entry';
export const RECEIVED_CHAR_HISTORY_ENTRY_SAME_CHAR = '[Dashboard Search Effects Socket Response] The id of the following characteristic from the flow is the same';
export const SELECT_FOLLOWING_CHARACTERISTIC = '[Dashboard Search Effects Socket Response] Select the next characteristic from the inspection flow.';

export const OPEN_QR_SCANNER = '[Dashboard QR Scanner Button] Open Scanner Dialog.';
export const QR_CODE_SCANNNED = '[Dashboard QR Code Scanned] Load scanned data.';
export const QR_DIALOG_CLOSED_WITHOUT_SCAN = '[Dashboard QR Dialog Closed without Scan] Skip data load.';
export const INVALID_QR_CODE = '[Dashboard Scanned QR is invalid] Invalid QR code.';

export const CLEAN_SEARCH_STATE = '[Dashboard On Destroy] Reset module state.';

export const SELECT_CHARACTERISTIC = '[Dashboard Search Characteristic Overview List selection] Select a characteristic.';

export const RECEIVED_SOCKET_PROGRESS = '[Dashboard Search Socket Progress] Received instance progress.';
export const UPDATED_CHARACTERISTIC_HISTORY = '[Dashboard Search Effects Index in sample not null when success received] Received update characteristic value.';
export const EDIT_EXCEPTION_STATUS =
  '[Dashboard Search Effects Index in sample not null when success exception response received] Received update characteristic exception.';

export const SELECT_STATISTICS_INDEX_IN_SAMPLE = '[Dashboard Inspection Protocol Widget History Item] Index in sample from history selected.';
export const CLEAR_INDEX_IN_SAMPLE_SELECTION = '[Dashboard Editing History Widget Deselect button] Clear index in sample selection';

export const TRY_SELECT_HISTORY_INDEX_IN_SAMPLE = '[Dashboard Editing History Widget History Item] Try change index in sample from history selected.';
export const SELECT_HISTORY_INDEX_IN_SAMPLE_SUCCESS = '[Dashboard API Response] Index in sample from history selected success.';
export const SELECT_HISTORY_INDEX_IN_SAMPLE_ERROR = '[Dashboard API Response] Index in sample from history selected error.';

export const UPDATE_HISTORY_FOR_INDEX_IN_SAMPLE = '[Dashboard API Response] Update history for index in sample.';

export const TRY_GET_MY_BRIDGES = '[Dashboard Init] Try load bridges';
export const GET_MY_BRIDGES_SUCCESS = '[Dashboard Effects API Response] Load bridges success';
export const GET_MY_BRIDGES_ERROR = '[Dashboard Effects API Response] Load bridges error';

export const TRY_ADD_BRIDGE = '[Dashboard My bridges Widget Add New] Add new bridge';
export const ADD_BRIDGE_SUCCESS = '[Dashboard Effects API Response] New bridge success';
export const ADD_BRIDGE_ERROR = '[Dashboard Effects API Response] New bridge error';
export const ADD_DUPLICATE_BRIDGE = '[Dashboard Effects] Skip adding the duplicated bridge.';

export const TRY_REMOVE_BRIDGE = '[Dashboard My bridges Widget Remove BRIDGE] Remove a bridge';
export const REMOVE_BRIDGE_SUCCESS = '[Dashboard Effects API Response] Remove bridge success';
export const REMOVE_BRIDGE_ERROR = '[Dashboard Effects API Response] Remove bridge error';

export const TRY_UPDATE_CHARACTERISTIC_DS = '[Dashboard Assign IE widget dropdown] Try update characteristic IE datasource';
export const UPDATE_CHARACTERISTIC_DS_SUCCESS = '[Dashboard API Response] Update characteristic IE datasource success';
export const UPDATE_CHARACTERISTIC_DS_ERROR = '[Dashboard API Response] Update characteristic IE datasource error';

export const TRY_SELECT_PROCESS_INSTANCE_WITH_STEP = '[Dashboard Process Selection widget step item click] Set instance criteria with step.';
export const TRY_LOAD_PROCESS_INSTANCE_WITH_STEP = '[Dashboard Search Effects] Set instance criteria with step.';
export const SELECT_PROCESS_INSTANCE_WITH_STEP_SUCCESS = '[Dashboard Search Effects] Set instance criteria with step success.';
export const SELECT_PROCESS_INSTANCE_WITH_STEP_ERROR = '[Dashboard Search Effects] Set instance criteria with step error.';

export const TRY_UNLOCK_PROCESS_INSTANCE = '[Dashboard Widget Unlock Button] Try unlock instance.';
export const UNLOCK_PROCESS_INSTANCE_SUCCESS = '[Dashboard Search API Response] Unlock instance success.';
export const UNLOCK_PROCESS_INSTANCE_ERROR = '[Dashboard Search API Response] Unlock instance error.';

export const TRY_RELEASE_PROCESS_INSTANCE_LOCK = '[Dashboard Characteristic Input value widget release lock button] Try release lock';
export const RELEASE_PROCESS_INSTANCE_LOCK_SUCCESS = '[Dashboard Characteristic Input value widget release lock button] Release lock success';
export const RELEASE_PROCESS_INSTANCE_LOCK_ERROR = '[Dashboard Characteristic Input value widget release lock button] Release lock error';

export const TRY_SET_IDENTITY_CHECK_STATUS = '[Dashboard Instance ID Check Button] Try update instance identity check status.';
export const SET_IDENTITY_CHECK_STATUS_SUCCESS = '[Dashboard Search API Response] Update instance identity check status success.';
export const SET_IDENTITY_CHECK_STATUS_ERROR = '[Dashboard Search API Response] Update instance identity check status error.';

export const TRY_SET_INSPECTION_DECISION_STATUS = '[Dashboard Instance Decision Button] Try update instance decision status.';
export const SET_INSPECTION_DECISION_STATUS_SUCCESS = '[Dashboard Search API Response] Update instance decision status success.';
export const SET_INSPECTION_DECISION_STATUS_ERROR = '[Dashboard Search API Response] Update instance decision status error.';

export const TRY_SET_INSPECTION_STATUS = '[Dashboard Instance Selection Widget Header] Try update instance status.';
export const SET_INSPECTION_STATUS_SUCCESS = '[Dashboard Search API Response] Update instance status success.';
export const SET_INSPECTION_STATUS_ERROR = '[Dashboard Search API Response] Update instance status error.';

export const TRY_UPDATE_CHARACTERISTIC_EXCEPTION = '[Dashboard Input Characteristic Report Exception] Try update instance characteristic exception.';
export const UPDATE_CHARACTERISTIC_EXCEPTION_SUCCESS = '[Dashboard Input Characteristic API Response] Update instance characteristic exception success.';
export const UPDATE_CHARACTERISTIC_EXCEPTION_ERROR = '[Dashboard Input Characteristic API Response] Update instance characteristic exception error.';

export const UPDATE_EXCEPTION_ALL_VALUES_ENTERED = '[Dashboard Exception Change] All values were entered.';

export const TRY_UPDATE_CHARACTERISTIC_DEFECTS = '[Dashboard Input Characteristic Report Defects] Try update instance characteristic defects.';
export const UPDATE_CHARACTERISTIC_DEFECTS_SUCCESS = '[Dashboard Input Characteristic API Response] Update instance characteristic defects success.';
export const UPDATE_CHARACTERISTIC_DEFECTS_ERROR = '[Dashboard Input Characteristic API Response] Update instance characteristic defects error.';

export const UPDATE_DEFECTS_ALL_VALUES_ENTERED = '[Dashboard Defects Change] All values were entered.';

export const TRY_REMOVE_CHARACTERISTIC_EXCEPTION = '[Dashboard Input Characteristic Remove Exception] Try remove instance characteristic exception.';
export const REMOVE_CHARACTERISTIC_EXCEPTION_SUCCESS = '[Dashboard Input Characteristic API Response] Remove instance characteristic exception success.';
export const REMOVE_CHARACTERISTIC_EXCEPTION_ERROR = '[Dashboard Input Characteristic API Response] Remove instance characteristic exception error.';

export const TRY_SEND_NEXT_SAMPLE_MESSAGE_WITH_PROPERTIES = '[Dashboard Sample widget] Try send increment default step sample with properties.';
export const TRY_SEND_NEXT_SAMPLE_MESSAGE_ON_FLOW_CHANGE =
  '[Dashboard Effects Flow Dashboard change] Try send increment default step sample if the flow dashboard is IN_PROGRESS and step is completed.';
export const TRY_SEND_NEXT_SAMPLE_MESSAGE = '[Dashboard Sample widget] Try send increment default step sample.';
export const SEND_NEXT_SAMPLE_MESSAGE_SUCCESS = '[Dashboard API Response] Send increment default step sample success.';
export const SEND_NEXT_SAMPLE_MESSAGE_ERROR = '[DDashboard API Response] Send increment default step sample error.';

export const TRY_SEND_STEP_FINISH_MESSAGE = '[Dashboard Sample widget] Try send finish message.';
export const SEND_STEP_FINISH_MESSAGE_SUCCESS = '[Dashboard API Response] Sent finish message success.';
export const SEND_STEP_FINISH_MESSAGE_ERROR = '[Dashboard API Response] Sent finish message error.';

export const TRY_DECIDE_ALL_CHARACTERISTICS_IN_BLOCK = '[Dashboard Input Characteristic Decide All] Try decide all characteristics in block.';
export const DECIDE_ALL_CHARACTERISTICS_IN_BLOCK_SUCCESS = '[Dashboard Input Characteristic API Response] Decide all characteristics in block success.';
export const DECIDE_ALL_CHARACTERISTICS_IN_BLOCK_ERROR = '[Dashboard Input Characteristic API Response] Decide all characteristics in block error.';

export const TRY_CHANGE_SELECTED_SAMPLE = '[Dashboard Sample widget sample change] Change selected sample data';
export const CHANGE_SELECTED_SAMPLE_SUCCESS = '[Dashboard API Response] Change selected sample data success';
export const CHANGE_SELECTED_SAMPLE_ERROR = '[Dashboard API Response] Change selected sample data error';

export const TRY_UPDATE_SYNC_STATUS = '[Dashboard Sync status widget] Try update sync status.';
export const UPDATE_SYNC_STATUS_SUCCESS = '[Dashboard API Response] Update sync status success.';
export const UPDATE_SYNC_STATUS_ERROR = '[Dashboard API Response] Update sync status error.';

export const TRY_CLEAR_SYNC_STATUS_ERROR = '[Dashboard Sync status widget] Clear sync status error.';
export const CLEAR_SYNC_STATUS_ERROR_SUCCESS = '[Dashboard API Response] Sync status error success.';
export const CLEAR_SYNC_STATUS_ERROR_ERROR = '[Dashboard API Response] Sync status error error.';

export const TRY_UPDATE_SAMPLE_BATCH_NUMBER = '[Dashboard Batch number widget] Try update sample number';
export const UPDATE_SAMPLE_BATCH_NUMBER_SUCCESS = '[Dashboard Batch number widget] Update sample number success';
export const UPDATE_SAMPLE_BATCH_NUMBER_ERROR = '[Dashboard Batch number widget] Update sample number error';

export const TRY_FINISH_INSTANCE = '[Dashboard Finish Button] Try finish instance.';
export const FINISH_INSTANCE_SUCCESS = '[Dashboard API Response] Finish instance success.';
export const FINISH_INSTANCE_ERROR = '[Dashboard API Response] Finish instance error.';

export const TRY_SUBMIT_BULK_CHAR_VALUE_ZERO_MESSAGE = '[Dashboard characteristic input widget form submit] Try submit bulk zero message.';
export const ZERO_MESSAGE_BULK_CHAR_VALUE_SUBMIT_SUCCESS = '[Dashboard characteristic API Response] Submit bulk zero message success.';
export const ZERO_MESSAGE_BULK_CHAR_VALUE_SUBMIT_ERROR = '[Dashboard characteristic API Response] Submit bulk zero message error.';

export const UPDATE_SERIAL_NUMBER = '[Dashboard Serial Number Widget] Update serial number value.';

export class TryFinishInstance implements ActionWithPayload<InspectionStatus> {
  readonly type = TRY_FINISH_INSTANCE;
  constructor(public payload: InspectionStatus) {}
}

export class FinishInstanceSuccess implements Action {
  readonly type = FINISH_INSTANCE_SUCCESS;
}

export class FinishInstanceError implements Action {
  readonly type = FINISH_INSTANCE_ERROR;
}

export class TryUpdateSampleBatchNumber implements ActionWithPayload<string> {
  readonly type = TRY_UPDATE_SAMPLE_BATCH_NUMBER;
  constructor(public payload: string) {}
}

export class UpdateSampleBatchNumberSuccess implements Action {
  readonly type = UPDATE_SAMPLE_BATCH_NUMBER_SUCCESS;
}

export class UpdateSampleBatchNumberError implements Action {
  readonly type = UPDATE_SAMPLE_BATCH_NUMBER_ERROR;
}

export class TryClearSyncError implements Action {
  readonly type = TRY_CLEAR_SYNC_STATUS_ERROR;
}

export class ClearSyncStatusSuccess implements Action {
  readonly type = CLEAR_SYNC_STATUS_ERROR_SUCCESS;
}

export class ClearSyncStatusError implements Action {
  readonly type = CLEAR_SYNC_STATUS_ERROR_ERROR;
}

export class TryUpdateSyncStatus implements ActionWithPayload<SyncStatus> {
  readonly type = TRY_UPDATE_SYNC_STATUS;
  constructor(public payload: SyncStatus) {}
}

export class UpdateSyncStatusSuccess implements Action {
  readonly type = UPDATE_SYNC_STATUS_SUCCESS;
}

export class UpdateSyncStatusError implements Action {
  readonly type = UPDATE_SYNC_STATUS_ERROR;
}

export class ResetCriteriaOnRoute implements Action {
  readonly type = RESET_CRITERIA_ON_ROUTE;
}

export class TryChangeSelectedSample implements ActionWithPayload<number> {
  readonly type = TRY_CHANGE_SELECTED_SAMPLE;
  constructor(public payload: number) {}
}

export class ChangeSelectedSampleSuccess implements ActionWithPayload<StepInstanceDTO> {
  readonly type = CHANGE_SELECTED_SAMPLE_SUCCESS;
  constructor(public payload: StepInstanceDTO) {}
}

export class ChangeSelectedSampleError implements Action {
  readonly type = CHANGE_SELECTED_SAMPLE_ERROR;
}

export class TryLoadCharacteristicSampleData implements Action {
  readonly type = TRY_GET_CHARACTERISTIC_SAMPLE_DATA;
}

export class LoadCharacteristicSampleDataSuccess implements ActionWithPayload<CharacteristicSample[]> {
  readonly type = GET_CHARACTERISTIC_SAMPLE_DATA_SUCCESS;
  constructor(public payload: CharacteristicSample[]) {}
}

export class LoadCharacteristicSampleDataError implements Action {
  readonly type = GET_CHARACTERISTIC_SAMPLE_DATA_ERROR;
}

export class TryDecideAllCharacteristicsInBlock
  implements
    ActionWithPayload<{
      value: boolean;
      comment: CommentData;
      attachments: DocumentInfo[];
      valueStatus: CharacteristicValueStatus;
    }>
{
  readonly type = TRY_DECIDE_ALL_CHARACTERISTICS_IN_BLOCK;
  constructor(
    public payload: {
      value: boolean;
      comment: CommentData;
      attachments: DocumentInfo[];
      valueStatus: CharacteristicValueStatus;
    },
  ) {}
}

export class DecideAllCharacteristicsInBlockSuccess implements ActionWithPayload<StepInstanceDTO> {
  readonly type = DECIDE_ALL_CHARACTERISTICS_IN_BLOCK_SUCCESS;
  constructor(public payload: StepInstanceDTO) {}
}

export class DecideAllCharacteristicsInBlockError implements Action {
  readonly type = DECIDE_ALL_CHARACTERISTICS_IN_BLOCK_ERROR;
}

export class TrySendStepFinishMessage implements Action {
  readonly type = TRY_SEND_STEP_FINISH_MESSAGE;
}

export class SendStepFinishMessageSuccess implements ActionWithPayload<{ instanceId: string; stepId: string }> {
  readonly type = SEND_STEP_FINISH_MESSAGE_SUCCESS;
  constructor(public payload: { instanceId: string; stepId: string }) {}
}
export class SendStepFinishMessageError implements Action {
  readonly type = SEND_STEP_FINISH_MESSAGE_ERROR;
}

export class TrySendNextSampleMessageOnFlowChange implements Action {
  readonly type = TRY_SEND_NEXT_SAMPLE_MESSAGE_ON_FLOW_CHANGE;
}

export class TrySendNextSampleMessage implements Action {
  readonly type = TRY_SEND_NEXT_SAMPLE_MESSAGE;
}

export class SendNextSampleMessageSuccess implements ActionWithPayload<StepInstanceDTO> {
  readonly type = SEND_NEXT_SAMPLE_MESSAGE_SUCCESS;
  constructor(public payload: StepInstanceDTO) {}
}

export class SendNextSampleMessageError implements Action {
  readonly type = SEND_NEXT_SAMPLE_MESSAGE_ERROR;
}

export class TrySendNextSampleMessageWithRequiredProperties implements ActionWithPayload<Record<SampleRequiredPropertyKeyType, string>> {
  readonly type = TRY_SEND_NEXT_SAMPLE_MESSAGE_WITH_PROPERTIES;
  constructor(public payload: Record<SampleRequiredPropertyKeyType, string>) {}
}

export class EditExceptionStatus implements Action {
  readonly type = EDIT_EXCEPTION_STATUS;
}

export class UpdateExceptionAllValuesEntered implements Action {
  readonly type = UPDATE_EXCEPTION_ALL_VALUES_ENTERED;
}

export class TryRemoveCharacteristicException implements Action {
  readonly type = TRY_REMOVE_CHARACTERISTIC_EXCEPTION;
}

export class RemoveCharacteristicExceptionSuccess implements ActionWithPayload<{ instanceId: string; characteristicId: string }> {
  readonly type = REMOVE_CHARACTERISTIC_EXCEPTION_SUCCESS;
  constructor(public payload: { instanceId: string; characteristicId: string }) {}
}

export class RemoveCharacteristicExceptionError implements Action {
  readonly type = REMOVE_CHARACTERISTIC_EXCEPTION_ERROR;
}

export class TryUpdateCharacteristicException implements ActionWithPayload<string> {
  readonly type = TRY_UPDATE_CHARACTERISTIC_EXCEPTION;
  constructor(public payload: string) {}
}

export class UpdateCharacteristicExceptionSuccess
  implements
    ActionWithPayload<{
      instanceId: string;
      characteristicId: string;
      exceptionId: string;
      edit: boolean;
    }>
{
  readonly type = UPDATE_CHARACTERISTIC_EXCEPTION_SUCCESS;
  constructor(
    public payload: {
      instanceId: string;
      characteristicId: string;
      exceptionId: string;
      edit: boolean;
    },
  ) {}
}

export class UpdateCharacteristicExceptionError implements Action {
  readonly type = UPDATE_CHARACTERISTIC_EXCEPTION_ERROR;
}

export class TryUpdateCharacteristicDefects implements ActionWithPayload<Catalog[]> {
  readonly type = TRY_UPDATE_CHARACTERISTIC_DEFECTS;
  constructor(public payload: Catalog[]) {}
}

export class UpdateCharacteristicDefectsSuccess
  implements
    ActionWithPayload<{
      instanceId: string;
      characteristicId: string;
      defects: Catalog[];
    }>
{
  readonly type = UPDATE_CHARACTERISTIC_DEFECTS_SUCCESS;
  constructor(
    public payload: {
      instanceId: string;
      characteristicId: string;
      defects: Catalog[];
    },
  ) {}
}

export class UpdateCharacteristicDefectsError implements Action {
  readonly type = UPDATE_CHARACTERISTIC_DEFECTS_ERROR;
}

export class TrySetIdentityCheckStatus implements ActionWithPayload<boolean> {
  readonly type = TRY_SET_IDENTITY_CHECK_STATUS;
  constructor(public payload: boolean) {}
}

export class SetIdentityCheckStatusSuccess implements ActionWithPayload<{ instanceId: string; value: boolean }> {
  readonly type = SET_IDENTITY_CHECK_STATUS_SUCCESS;
  constructor(public payload: { instanceId: string; value: boolean }) {}
}

export class SetIdentityCheckStatusError implements Action {
  readonly type = SET_IDENTITY_CHECK_STATUS_ERROR;
}

export class TrySetInspectionDecisionStatus implements ActionWithPayload<InspectionStatus> {
  readonly type = TRY_SET_INSPECTION_DECISION_STATUS;
  constructor(public payload: InspectionStatus) {}
}

export class SetInspectionDecisionStatusSuccess implements ActionWithPayload<ZeroMessage> {
  readonly type = SET_INSPECTION_DECISION_STATUS_SUCCESS;
  constructor(public payload: ZeroMessage) {}
}

export class SetInspectionDecisionStatusError implements Action {
  readonly type = SET_INSPECTION_DECISION_STATUS_ERROR;
}

export class TrySetInspectionStatus
  implements
    ActionWithPayload<{
      instanceId: string;
      diagramId: string;
      status: InspectionStatus;
    }>
{
  readonly type = TRY_SET_INSPECTION_STATUS;
  constructor(
    public payload: {
      instanceId: string;
      diagramId: string;
      status: InspectionStatus;
    },
  ) {}
}

export class SetInspectionStatusSuccess implements ActionWithPayload<ZeroMessage> {
  readonly type = SET_INSPECTION_STATUS_SUCCESS;
  constructor(public payload: ZeroMessage) {}
}

export class SetInspectionStatusError implements Action {
  readonly type = SET_INSPECTION_STATUS_ERROR;
}

export class InitSearchData implements Action {
  readonly type = INIT_SEARCH_DATA_FROM_USER_DATA;
}

export class TryReleaseLock implements Action {
  readonly type = TRY_RELEASE_PROCESS_INSTANCE_LOCK;
}

export class ReleaseLockSuccess implements ActionWithPayload<{ instanceId: string }> {
  readonly type = RELEASE_PROCESS_INSTANCE_LOCK_SUCCESS;
  constructor(public payload: { instanceId: string }) {}
}

export class ReleaseLockError implements Action {
  readonly type = RELEASE_PROCESS_INSTANCE_LOCK_ERROR;
}

export class TryUnlockProcessInstance implements Action {
  readonly type = TRY_UNLOCK_PROCESS_INSTANCE;
}

export class UnlockProcessInstanceSuccess implements ActionWithPayload<{ instanceId: string; currentUser: User }> {
  readonly type = UNLOCK_PROCESS_INSTANCE_SUCCESS;
  constructor(public payload: { instanceId: string; currentUser: User }) {}
}

export class UnlockProcessInstanceError implements Action {
  readonly type = UNLOCK_PROCESS_INSTANCE_ERROR;
}

export class TrySelectProcessInstanceWithStep implements ActionWithPayload<{ instanceId: string; stepId: string }> {
  readonly type = TRY_SELECT_PROCESS_INSTANCE_WITH_STEP;
  constructor(public payload: { instanceId: string; stepId: string }) {}
}

export class TryLoadProcessInstanceWithStep implements ActionWithPayload<{ instanceId: string; stepId: string }> {
  readonly type = TRY_LOAD_PROCESS_INSTANCE_WITH_STEP;
  constructor(public payload: { instanceId: string; stepId: string }) {}
}

export class SelectProcessInstanceWithStepSuccess
  implements
    ActionWithPayload<{
      instance: ProcessInstance;
      instanceProgress: ProcessInstanceDTO;
      stepId: string;
      characteristicId: string;
      sampleData: Sample[];
    }>
{
  readonly type = SELECT_PROCESS_INSTANCE_WITH_STEP_SUCCESS;
  constructor(
    public payload: {
      instance: ProcessInstance;
      instanceProgress: ProcessInstanceDTO;
      stepId: string;
      characteristicId: string;
      sampleData: Sample[];
    },
  ) {}
}

export class SelectProcessInstanceWithStepError implements Action {
  readonly type = SELECT_PROCESS_INSTANCE_WITH_STEP_ERROR;
}

export class SkipLoadingCharacteristicHistory implements Action {
  readonly type = SKIP_LOADING_CHARACTERISTIC_HISTORY;
}

export class TryUpdateCharacteristicDS
  implements
    ActionWithPayload<{
      characteristicId: string;
      bridgeId: string;
      boxId: string;
      channel: number;
    }>
{
  readonly type = TRY_UPDATE_CHARACTERISTIC_DS;
  constructor(
    public payload: {
      characteristicId: string;
      bridgeId: string;
      boxId: string;
      channel: number;
    },
  ) {}
}

export class UpdateCharacteristicDSSuccess
  implements
    ActionWithPayload<{
      characteristicId: string;
      ie: CharacteristicInspectionEquipment;
    }>
{
  readonly type = UPDATE_CHARACTERISTIC_DS_SUCCESS;
  constructor(
    public payload: {
      characteristicId: string;
      ie: CharacteristicInspectionEquipment;
    },
  ) {}
}

export class UpdateCharacteristicDSError implements Action {
  readonly type = UPDATE_CHARACTERISTIC_DS_ERROR;
}

export class TryGetMyBridges implements Action {
  readonly type = TRY_GET_MY_BRIDGES;
}

export class GetMyBridgesSuccess implements ActionWithPayload<Catalog[]> {
  readonly type = GET_MY_BRIDGES_SUCCESS;
  constructor(public payload: Catalog[]) {}
}

export class GetMyBridgesError implements Action {
  readonly type = GET_MY_BRIDGES_ERROR;
}

export class TryAddBridge implements ActionWithPayload<string> {
  readonly type = TRY_ADD_BRIDGE;
  constructor(public payload: string) {}
}

export class AddBridgeSuccess implements ActionWithPayload<Catalog> {
  readonly type = ADD_BRIDGE_SUCCESS;
  constructor(public payload: Catalog) {}
}

export class AddBridgeError implements Action {
  readonly type = ADD_BRIDGE_ERROR;
}

export class AddDuplicateBridge implements Action {
  readonly type = ADD_DUPLICATE_BRIDGE;
}

export class TryRemoveBridge implements ActionWithPayload<string> {
  readonly type = TRY_REMOVE_BRIDGE;
  constructor(public payload: string) {}
}

export class RemoveBridgeSuccess implements ActionWithPayload<string> {
  readonly type = REMOVE_BRIDGE_SUCCESS;
  constructor(public payload: string) {}
}

export class RemoveBridgeError implements Action {
  readonly type = REMOVE_BRIDGE_ERROR;
}

export class ClearIndexInSampleSelection implements Action {
  readonly type = CLEAR_INDEX_IN_SAMPLE_SELECTION;
}

export class SelectStatisticsIndexInSample implements ActionWithPayload<number> {
  readonly type = SELECT_STATISTICS_INDEX_IN_SAMPLE;
  constructor(public payload: number) {}
}

export class TrySelectHistoryIndexInSample implements ActionWithPayload<{ sample: number; index: number }> {
  readonly type = TRY_SELECT_HISTORY_INDEX_IN_SAMPLE;
  constructor(public payload: { sample: number; index: number }) {}
}

export class SelectHistoryIndexInSampleSuccess implements ActionWithPayload<{ index: number; progress: StepInstanceDTO }> {
  readonly type = SELECT_HISTORY_INDEX_IN_SAMPLE_SUCCESS;
  constructor(public payload: { index: number; progress: StepInstanceDTO }) {}
}

export class UpdateHistoryForIndexInSample implements ActionWithPayload<CharacteristicHistory[]> {
  readonly type = UPDATE_HISTORY_FOR_INDEX_IN_SAMPLE;
  constructor(public payload: CharacteristicHistory[]) {}
}

export class SelectHistoryIndexInSampleError implements Action {
  readonly type = SELECT_HISTORY_INDEX_IN_SAMPLE_ERROR;
}

export class UpdatedCharacteristicHistory implements Action {
  readonly type = UPDATED_CHARACTERISTIC_HISTORY;
}
export class ReceivedSocketHistoryEntry implements ActionWithPayload<ProcessInstanceDTO> {
  readonly type = RECEIVED_SOCKET_PROGRESS;
  constructor(public payload: ProcessInstanceDTO) {}
}

export class SelectCharacteristic implements ActionWithPayload<string> {
  readonly type = SELECT_CHARACTERISTIC;
  constructor(public payload: string) {}
}

export class OpenQRScanner implements Action {
  readonly type = OPEN_QR_SCANNER;
}

export class QRCodeScanned
  implements
    ActionWithPayload<{
      diagramId: string;
      instanceId: string;
      stepId: string;
      characteristicId: string;
    }>
{
  readonly type = QR_CODE_SCANNNED;
  constructor(
    public payload: {
      diagramId: string;
      instanceId: string;
      stepId: string;
      characteristicId: string;
    },
  ) {}
}

export class QRDialogClosed implements Action {
  readonly type = QR_DIALOG_CLOSED_WITHOUT_SCAN;
}

export class InvalidQRCodeScanned implements Action {
  readonly type = INVALID_QR_CODE;
}

export class TrySelectNextStepCharacteristic implements Action {
  readonly type = TRY_SELECT_LOADED_STEP_CHARACTERISTIC;
}

export class SelectNextStepCharacteristicSuccess implements ActionWithPayload<Characteristic> {
  readonly type = SELECT_LOADED_STEP_CHARACTERISTIC_SUCCESS;
  constructor(public payload: Characteristic) {}
}

export class SelectNextStepCharacteristicError implements Action {
  readonly type = SELECT_LOADED_STEP_CHARACTERISTIC_ERROR;
}

export class RecievedCharacteristicHistoryEntry implements ActionWithPayload<CharacteristicHistory> {
  readonly type = RECIEVED_CHARACTERISTIC_HISTORY_ENTRY;
  constructor(public payload: CharacteristicHistory) {}
}

export class TryLoadCharacteristicHistory implements Action {
  readonly type = TRY_GET_CHARACTERISTIC_HISTORY;
}

export class LoadCharacteristicHistorySuccess implements ActionWithPayload<CharacteristicHistory[]> {
  readonly type = GET_CHARACTERISTIC_HISTORY_SUCCESS;
  constructor(public payload: CharacteristicHistory[]) {}
}

export class LoadCharacteristicHistoryError implements Action {
  readonly type = GET_CHARACTERISTIC_HISTORY_ERROR;
}

export class CommentZeroMessageSubmit implements ActionWithPayload<CommentData> {
  readonly type = COMMENT_ZERO_MESSAGE_SUBMIT;
  constructor(public payload: CommentData) {}
}

export class SkipFollowingCharacteristicSelection implements ActionWithPayload<CharacteristicHistory> {
  readonly type = RECEIVED_CHAR_HISTORY_ENTRY_SAME_CHAR;
  constructor(public payload: CharacteristicHistory) {}
}

export class SelectFollowingCharacteristic implements ActionWithPayload<Characteristic> {
  readonly type = SELECT_FOLLOWING_CHARACTERISTIC;
  constructor(public payload: Characteristic) {}
}

export class CleanDashboardSearchState implements Action {
  readonly type = CLEAN_SEARCH_STATE;
}

export class TryLoadMissingStepFromCriteria implements Action {
  readonly type = TRY_LOAD_MISSING_STEP_FROM_CRITERIA;
}

export class FinishCriteriaUpdate implements Action {
  readonly type = FINISH_CRITERIA_UPDATE;
}

export class NavigatedToDashboard implements Action {
  readonly type = NAVIGATED_TO_DASHBOARD;
}

export class UpdateInputtedNoteCharacteristicNoteWidget implements ActionWithPayload<CommentData> {
  readonly type = UPDATE_INPUTTED_NOTE_FOR_PARAMETER_CHARACTERISTIC_NOTE_WIDGET;
  constructor(public payload: CommentData) {}
}

export class TryUpdateActionCatalogForCharacteristicInSample implements ActionWithPayload<string> {
  readonly type = TRY_UPDATE_ACTION_CATALOG_FOR_CHARACTERISTIC_IN_SAMPLE;
  constructor(public payload: string) {}
}

export class UpdateActionCatalogForCharacteristicInSampleSuccess implements ActionWithPayload<ZeroMessage> {
  readonly type = UPDATE_ACTION_CATALOG_FOR_CHARACTERISTIC_IN_SAMPLE_SUCCESS;
  constructor(public payload: ZeroMessage) {}
}

export class UpdateActionCatalogForCharacteristicInSampleError implements Action {
  readonly type = UPDATE_ACTION_CATALOG_FOR_CHARACTERISTIC_IN_SAMPLE_ERROR;
}

export class TryUpdateInternalComplaintForSample implements ActionWithPayload<Complaint> {
  readonly type = TRY_UPDATE_INTERNAL_COMPLAINT_FOR_SAMPLE;
  constructor(public payload: Complaint) {}
}

export class UpdateInternalComplaintForSampleSuccess implements ActionWithPayload<ZeroMessage> {
  readonly type = UPDATE_INTERNAL_COMPLAINT_FOR_SAMPLE_SUCCESS;
  constructor(public payload: ZeroMessage) {}
}

export class UpdateInternalComplaintForSampleError implements Action {
  readonly type = UPDATE_INTERNAL_COMPLAINT_FOR_SAMPLE_ERROR;
}

export class UpdateInputtedNoteInputCharacteristicWidget implements ActionWithPayload<CommentData> {
  readonly type = UPDATE_INPUTTED_NOTE_FOR_PARAMETER_INPUT_CHARACTERISTIC;
  constructor(public payload: CommentData) {}
}

export class UpdateInputtedDocumentsDocumentWidget implements ActionWithPayload<DocumentStore[]> {
  readonly type = UPDATE_INPUTTED_DOCUMENTS_FOR_PARAMETER_DOCUMENTS_WIDGET;
  constructor(public payload: DocumentStore[]) {}
}

export class UpdateInputtedDocumentsInputCharacteristicWidget implements ActionWithPayload<DocumentStore[]> {
  readonly type = UPDATE_INPUTTED_DOCUMENTS_FOR_PARAMETER_INPUT_CHARACTERISTIC;
  constructor(public payload: DocumentStore[]) {}
}

export class TrySubmitBulkCharValueZeroMessage implements ActionWithPayload<{ values: { [characteristicId: string]: string[] } }> {
  readonly type = TRY_SUBMIT_BULK_CHAR_VALUE_ZERO_MESSAGE;
  constructor(public payload: { values: { [characteristicId: string]: string[] } }) {}
}


export class ZeroMessageBulkCharValueSubmitSuccess implements ActionWithPayload<ZeroMessage> {
  readonly type = ZERO_MESSAGE_BULK_CHAR_VALUE_SUBMIT_SUCCESS;
  constructor(public payload: ZeroMessage) {}
}

export class ZeroMessageBulkCharValueSubmitError implements Action {
  readonly type = ZERO_MESSAGE_BULK_CHAR_VALUE_SUBMIT_ERROR;
}

export class TryLoadCriteriaSearchData
  implements
    ActionWithPayload<{
      diagramId?: string;
      instanceId?: string;
      stepId?: string;
      characteristicId?: string;
    }>
{
  readonly type = TRY_LOAD_CRITERIA_SEARCH_DATA;
  constructor(
    public payload: {
      diagramId?: string;
      instanceId?: string;
      stepId?: string;
      characteristicId?: string;
    },
  ) {}
}

export class LoadCriteriaSearchDataSuccess
  implements
    ActionWithPayload<{
      instance: ProcessInstance;
      instanceProgress: ProcessInstanceDTO;
      stepId: string;
      characteristicId: string;
      indexInSample: number;
      sampleData: Sample[];
    }>
{
  readonly type = LOAD_CRITERIA_SEARCH_DATA_SUCCESS;
  constructor(
    public payload: {
      instance: ProcessInstance;
      stepId: string;
      instanceProgress: ProcessInstanceDTO;
      characteristicId: string;
      indexInSample: number;
      sampleData: Sample[];
    },
  ) {}
}

export class LoadedCriteriaDataIsComplete implements Action {
  readonly type = LOADED_CRITERIA_DATA_IS_COMPLETE;
}

export class LoadCriteriaSearchDataError implements Action {
  readonly type = LOAD_CRITERIA_SEARCH_DATA_ERROR;
}

export class ClearDashboardHeaderSearchCriteria implements Action {
  readonly type = CLEAR_DASHBOARD_HEADER_SEARCH_CRITERIA;
}

export class TrySubmitCharValueZeroMessage
  implements
    ActionWithPayload<{
      value?: number;
      documents?: DocumentStore[];
      note?: CommentData;
      valueDate?: Date;
      valueStatus?: CharacteristicValueStatus;
      serialNumber?: string;
    }>
{
  readonly type = TRY_SUBMIT_CHAR_VALUE_ZERO_MESSAGE;
  constructor(
    public payload: {
      value?: number;
      documents?: DocumentStore[];
      note?: CommentData;
      valueDate?: Date;
      valueStatus?: CharacteristicValueStatus;
      serialNumber?: string;
    },
  ) {}
}

export class UpdateSerialNumber implements ActionWithPayload<string> {
  readonly type = UPDATE_SERIAL_NUMBER;
  constructor(public payload: string) {}
}

export class TrySendMailOnToleranceViolation implements Action {
  readonly type = TRY_SEND_MAIL_ON_TOLERANCE_VIOLATION;
}

export class SendMailOnToleranceViolationSuccess implements Action {
  readonly type = SEND_MAIL_ON_TOLERANCE_VIOLATION_SUCCESS;
}

export class SendMailOnToleranceViolationError implements Action {
  readonly type = SEND_MAIL_ON_TOLERANCE_VIOLATION_ERROR;
}

export class SubmitCharValueZeroMessageSuccess
  implements
    ActionWithPayload<{
      message: ZeroMessage;
      edit: boolean;
      didLock: boolean;
      currentUser: User;
    }>
{
  readonly type = ZERO_MESSAGE_CHAR_VALUE_SUBMIT_SUCCESS;
  constructor(
    public payload: {
      message: ZeroMessage;
      edit: boolean;
      didLock: boolean;
      currentUser: User;
      valueStatus: CharacteristicValueStatus;
    },
  ) {}
}

export class SubmitCharValueZeroMessageError implements Action {
  readonly type = ZERO_MESSAGE_CHAR_VALUE_SUBMIT_ERROR;
}

export class ZeroMessageCharValueCharIsSame implements Action {
  readonly type = ZERO_MESSAGE_CHAR_VALUE_CHAR_IS_SAME;
}

export class ZeroMessageCharValueAllValuesEntered implements Action {
  readonly type = ZERO_MESSAGE_CHAR_VALUE_ALL_VALUES_ENTERED;
}

export class InstanceIsAlreadyLockedByAnotherUser implements Action {
  readonly type = INSTANCE_IS_ALREADY_LOCKED_BY_ANOTHER_USER;
}

export class SelectNextCharacteristic implements Action {
  readonly type = SELECT_NEXT_CHARACTERISTIC;
}

export class SelectPreviousCharacteristic implements Action {
  readonly type = SELECT_PREVIOUS_CHARACTERISTIC;
}

export class TryLoadStepById implements ActionWithPayload<string> {
  readonly type = TRY_LOAD_STEP_BY_ID;
  constructor(public payload: string) {}
}

export class TryingToLoadTheSameStep implements Action {
  readonly type = TRYING_TO_LOAD_THE_SAME_STEP;
}

export class TryLoadStepAfterCompletedStep implements ActionWithPayload<string> {
  readonly type = TRY_LOAD_STEP_AFTER_COMPLETED_STEP;
  constructor(public payload: string) {}
}

export class LoadStepByIdSuccess implements ActionWithPayload<DiagramStep> {
  readonly type = LOAD_STEP_BY_ID_SUCCESS;
  constructor(public payload: DiagramStep) {}
}

export class LoadStepByIdError implements ActionWithPayload<IRestError> {
  readonly type = LOAD_STEP_BY_ID_ERROR;
  constructor(public payload: RestError) {}
}

export class NoStepIdToLoadStep implements Action {
  readonly type = NO_STEP_ID_TO_LOAD;
}

export class StepForLoadNotInDiagram implements Action {
  readonly type = STEP_FOR_LOAD_NOT_IN_DIAGRAM;
}

export class TryLoadNextStep implements ActionWithPayload<string> {
  readonly type = TRY_LOAD_NEXT_STEP;
  constructor(public payload: string) {}
}

export class NextLoadStepIsSame implements Action {
  readonly type = NEXT_LOAD_STEP_IS_SAME;
}

export class NoNextStepToLoad implements Action {
  readonly type = NO_NEXT_STEP_TO_LOAD;
}

export class LoadNextStepSuccess implements ActionWithPayload<DiagramStep> {
  readonly type = LOAD_NEXT_STEP_SUCCESS;
  constructor(public payload: DiagramStep) {}
}

export class LoadNextStepError implements ActionWithPayload<IRestError> {
  readonly type = LOAD_NEXT_STEP_ERROR;
  constructor(public payload: RestError) {}
}

export type DASHSEActions =
  | InitSearchData
  | OpenQRScanner
  | QRCodeScanned
  | QRDialogClosed
  | InvalidQRCodeScanned
  | SkipLoadingCharacteristicHistory
  | TryUnlockProcessInstance
  | UnlockProcessInstanceSuccess
  | UnlockProcessInstanceError
  | TryLoadStepById
  | TryingToLoadTheSameStep
  | LoadStepByIdSuccess
  | LoadStepByIdError
  | NoStepIdToLoadStep
  | StepForLoadNotInDiagram
  | ResetCriteriaOnRoute
  | ClearDashboardHeaderSearchCriteria
  | SelectNextCharacteristic
  | SelectPreviousCharacteristic
  | TrySubmitCharValueZeroMessage
  | SubmitCharValueZeroMessageSuccess
  | SubmitCharValueZeroMessageError
  | ZeroMessageCharValueCharIsSame
  | ZeroMessageCharValueAllValuesEntered
  | InstanceIsAlreadyLockedByAnotherUser
  | TryLoadCriteriaSearchData
  | LoadCriteriaSearchDataSuccess
  | LoadedCriteriaDataIsComplete
  | LoadCriteriaSearchDataError
  | UpdateInputtedDocumentsDocumentWidget
  | UpdateInputtedDocumentsInputCharacteristicWidget
  | UpdateInputtedNoteCharacteristicNoteWidget
  | UpdateInputtedNoteInputCharacteristicWidget
  | TryUpdateActionCatalogForCharacteristicInSample
  | UpdateActionCatalogForCharacteristicInSampleSuccess
  | UpdateActionCatalogForCharacteristicInSampleError
  | TryUpdateInternalComplaintForSample
  | UpdateInternalComplaintForSampleSuccess
  | UpdateInternalComplaintForSampleError
  | NavigatedToDashboard
  | FinishCriteriaUpdate
  | CleanDashboardSearchState
  | SelectFollowingCharacteristic
  | TryLoadNextStep
  | NextLoadStepIsSame
  | NoNextStepToLoad
  | LoadNextStepSuccess
  | LoadNextStepError
  | TryLoadCharacteristicHistory
  | LoadCharacteristicHistorySuccess
  | LoadCharacteristicHistoryError
  | RecievedCharacteristicHistoryEntry
  | TrySelectNextStepCharacteristic
  | SelectNextStepCharacteristicSuccess
  | SelectNextStepCharacteristicError
  | SkipFollowingCharacteristicSelection
  | CommentZeroMessageSubmit
  | TryLoadStepAfterCompletedStep
  | ReceivedSocketHistoryEntry
  | SelectCharacteristic
  | SelectStatisticsIndexInSample
  | TrySelectHistoryIndexInSample
  | UpdatedCharacteristicHistory
  | ClearIndexInSampleSelection
  | TryGetMyBridges
  | GetMyBridgesSuccess
  | GetMyBridgesError
  | TryAddBridge
  | AddBridgeSuccess
  | AddBridgeError
  | AddDuplicateBridge
  | TryRemoveBridge
  | RemoveBridgeSuccess
  | TryUpdateCharacteristicDS
  | UpdateCharacteristicDSSuccess
  | UpdateCharacteristicDSError
  | TrySelectProcessInstanceWithStep
  | TryLoadProcessInstanceWithStep
  | SelectProcessInstanceWithStepSuccess
  | SelectProcessInstanceWithStepError
  | RemoveBridgeError
  | TryReleaseLock
  | ReleaseLockSuccess
  | ReleaseLockError
  | TrySetInspectionDecisionStatus
  | SetInspectionDecisionStatusSuccess
  | SetInspectionDecisionStatusError
  | TrySetInspectionStatus
  | SetInspectionStatusSuccess
  | SetInspectionStatusError
  | TrySetIdentityCheckStatus
  | SetIdentityCheckStatusSuccess
  | SetIdentityCheckStatusError
  | TryRemoveCharacteristicException
  | RemoveCharacteristicExceptionSuccess
  | RemoveCharacteristicExceptionError
  | TrySendNextSampleMessage
  | SendNextSampleMessageSuccess
  | SendNextSampleMessageError
  | TrySendNextSampleMessageWithRequiredProperties
  | TryUpdateCharacteristicException
  | UpdateCharacteristicExceptionSuccess
  | UpdateCharacteristicExceptionError
  | TrySendStepFinishMessage
  | SendStepFinishMessageSuccess
  | SendStepFinishMessageError
  | EditExceptionStatus
  | UpdateExceptionAllValuesEntered
  | TryDecideAllCharacteristicsInBlock
  | DecideAllCharacteristicsInBlockSuccess
  | DecideAllCharacteristicsInBlockError
  | TryLoadCharacteristicSampleData
  | LoadCharacteristicSampleDataSuccess
  | LoadCharacteristicSampleDataError
  | TryChangeSelectedSample
  | ChangeSelectedSampleSuccess
  | ChangeSelectedSampleError
  | TryClearSyncError
  | ClearSyncStatusSuccess
  | ClearSyncStatusError
  | TryUpdateSyncStatus
  | UpdateSyncStatusSuccess
  | UpdateSyncStatusError
  | TryUpdateSampleBatchNumber
  | UpdateSampleBatchNumberSuccess
  | UpdateSampleBatchNumberError
  | TrySelectHistoryIndexInSample
  | SelectHistoryIndexInSampleSuccess
  | SelectHistoryIndexInSampleError
  | TryUpdateCharacteristicDefects
  | UpdateCharacteristicDefectsSuccess
  | UpdateCharacteristicDefectsError
  | TrySendNextSampleMessageOnFlowChange
  | TryFinishInstance
  | FinishInstanceSuccess
  | FinishInstanceError
  | TrySubmitBulkCharValueZeroMessage
  | ZeroMessageBulkCharValueSubmitSuccess
  | ZeroMessageBulkCharValueSubmitError
  | FinishInstanceError
  | UpdateSerialNumber
  | UpdateHistoryForIndexInSample;
