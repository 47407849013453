import { BaseModel } from 'app/core/base.model';

import { WidgetData } from './widget.model';
import { Textstore } from 'app/shared/lang-dialog/textstore.model';
import { ProductLine } from 'app/shared/license/licensedetails.model';

export class Dashboard extends BaseModel {
   constructor(
      public id?: string,
      public title = Textstore.withInitatedLanguages(),
      public description = '',
      public widgets: WidgetData[] = [],
      public shared?: boolean,
      public userId?: string,
      public partId?: string,
      public gridMargin = 20,
      public strategyCanvas = false,
      public isParent = false,
      public parentId?: string,
      public isDefault = false,
      public flowType?: DashboardFlowType,
      public flow?: FlowDataType,
      public parentTitle?: string,
      public icon?: string,
      public isSystemData = false,
      public isTemplate = false,
      public productLine?: ProductLine,
   ) {
      super();
   }
}

export enum DashboardFlowType {
   INCOMING_INSPECTION = 'INCOMING_INSPECTION',
   OUTGOING_INSPECTION = 'OUTGOING_INSPECTION',
   PRODUCTION_INSPECTION = 'PRODUCTION_INSPECTION',
   SPC = 'SPC',
   NONE = 'NONE'
}

export interface FlowDataType {
   NO_SELECTION: string;
   NEW: string;
   IN_PROGRESS: string;
   FINISHED: string;
   DECIDED: string;
}

export interface DashboardIcon {
   icon: string;
   title: string;
}
