export abstract class CharacteristicType {
  abstract readonly type: CharacteristicDataType;

  constructor(public id: string) {}
}

export enum CharacteristicDataType {
  BINARY = 'BINARY',
  INFO = 'INFO',
  TEXT = 'TEXT',
  NUMBER = 'NUMBER',
  DATE = 'DATE',
  TIME = 'TIME',
  GRADE = 'GRADE',
  PERCENT = 'PERCENT',
  CODE = 'CODE',
}

export interface ColorModel {
  colorSchema: ColorSchema;
  frequency: ColorModelFrequency;
  targetValue?: number;
  startValue?: number;
  decimals?: number;
  lowerOutlier?: number;
  ltl?: number;
  utl?: number;
  upperOutlier?: number;
}

export interface StatModel {
  cpkReference: number;
  windowSize: number;

  /** egr_u_xq */
  lclForMeanValue?: number;
  /** egr_o_xq */
  uclForMeanValue?: number;
  /** egr_u_r */
  lclForRange?: number;
  /** egr_o_r */
  uclForRange?: number;
  /** egr_u_s */
  lclForStddev?: number;
  /** egr_o_s */
  uclForStddev?: number;
  /** wgr_u_xq */
  lowerWarningLimit?: number;
  /** wgr_o_xq */
  upperWarrningLimit?: number;
}

export enum ColorSchema {
  NONE = 'NONE',
  RAG = 'RAG',
  GAR = 'GAR',
  RGR = 'RGR',
  RG = 'RG',
  GR = 'GR',
}

export enum ColorModelFrequency {
  DAILY = 'DAILY',
  WEEKLY = 'WEEKLY',
  MONTHLY = 'MONTHLY',
  QUARTERLY = 'QUARTERLY',
  HALF_YEARLY = 'HALF_YEARLY',
  YEARLY = 'YEARLY',
}

export class DateCharacteristicType extends CharacteristicType {
  readonly type = CharacteristicDataType.DATE;

  constructor(id: string) {
    super(id);
  }
}

export class StringCharacteristicType extends CharacteristicType {
  readonly type = CharacteristicDataType.DATE;

  constructor(id: string) {
    super(id);
  }
}

export class BinaryCharacteristicType extends CharacteristicType {
  readonly type = CharacteristicDataType.BINARY;

  constructor(id: string) {
    super(id);
  }
}

export class InfoCharacteristicType extends CharacteristicType {
  readonly type = CharacteristicDataType.INFO;

  constructor(id: string) {
    super(id);
  }
}

export class NumberCharacteristicType extends CharacteristicType {
  readonly type = CharacteristicDataType.NUMBER;

  constructor(
    id: string,
    public colorModel: ColorModel,
    public statModel?: StatModel,
  ) {
    super(id);
  }
}

export class TimeCharacteristicType extends CharacteristicType {
  readonly type = CharacteristicDataType.TIME;

  constructor(id: string) {
    super(id);
  }
}

export class GradeCharacteristicType extends CharacteristicType {
  readonly type = CharacteristicDataType.GRADE;

  constructor(
    id: string,
    public colorModel: ColorModel,
  ) {
    super(id);
  }
}

export class PercentCharacteristicType extends CharacteristicType {
  readonly type = CharacteristicDataType.PERCENT;

  constructor(
    id: string,
    public colorModel: ColorModel,
  ) {
    super(id);
  }
}

export class TextCharacteristicType extends CharacteristicType {
  readonly type = CharacteristicDataType.TEXT;

  constructor(
    id: string,
    public colorModel: ColorModel,
  ) {
    super(id);
  }
}
