import { BaseModel } from '../../../../../core/base.model';
import { User, UserInfo } from 'app/shared';
import { ProcessDiagram, ProcessDiagramType } from '../../designers/process/model/process-diagram.model';

export class ProcessInstance extends BaseModel {
  /**
   * Transient field based on diagramId
   */
  diagram: ProcessDiagram | null;

  constructor(
    public id?: string,
    public number?: string,
    public startDate?: Date,
    public endDate?: Date,
    public deleted?: boolean,
    public diagramId?: string,
    public type?: ProcessDiagramType,
    public sync?: Synchronisation,
    public counterOk = 0,
    public counterNok = 0,
    public counterRework = 0,
    public latestInstance = true,
    public creator?: UserInfo,
    public lastModifiedBy?: string,
    public lastChanged?: Date,
    public createdDate?: Date,
    public lastCheckIn?: Date,
    public lastCheckInBy?: User,
    public status: InspectionStatus = InspectionStatus.NEW,
    public diagramInstance?: ProcessDiagram,
    public revisionNumber?: number,
    public hasSamples?: boolean,
  ) {
    super();
  }
}

export interface Synchronisation {
  error: number;
  description: string;
  syncStatus: SyncStatus;
}

export enum SyncStatus {
  NOT_READY = 'NOT_READY',
  READY = 'READY',
  SYNCED = 'SYNCED',
  SYNCING = 'SYNCING',
  FAULT = 'FAULT',
  ERROR = 'ERROR',
}

export enum InspectionStatus {
  NEW = 'NEW',
  IN_PROGRESS = 'IN_PROGRESS',
  PAUSED = 'PAUSED',
  CANCELED = 'CANCELED',
  FINISHED = 'FINISHED',
  NO_DECISION = 'NO_DECISION',
  ACCEPTED = 'ACCEPTED',
  NOT_ACCEPTED = 'NOT_ACCEPTED',
}
