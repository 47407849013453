import { ActionReducerMap, createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromRouter from '@ngrx/router-store';

import * as fromAuthenticationPage from './main/content/apps/authentication/authentication-store/authentication-store.reducer';

import * as fromFileManager from './main/content/apps/file-manager/store/file-manager.reducer';
import * as fromGoalDiagram from './main/content/apps/designers/goal/diagram/store/goal-diagram.reducer';
import * as fromGoalDesigner from './main/content/apps/designers/goal/designer/store/goal-designer.reducer';
import * as fromProcessDiagram from './main/content/apps/designers/process/diagram/store/process-diagram.reducer';
import * as fromKPIDesigner from './main/content/apps/designers/kpi/designer/store/kpi-designer.reducer';

import * as fromTaskManger from './main/content/apps/task/store/task.reducer';
import * as fromProfile from './main/content/apps/profile/store/profile.reducer';
import * as fromLicense from './main/content/apps/administration/license-manager/store/license-manager.reducer';
import * as fromLicenseUpgrade from './main/content/apps/administration/license-upgrade/store/license-upgrade.reducer';
import * as fromUser from './main/content/apps/administration/user-manager/store/user-manager.reducer';
import * as fromCat from './main/content/apps/catalog/catalog/store/catalog.reducer';
import * as fromCatDialog from './main/content/apps/catalog/catalog/store-dialog/catalog-dialog.reducer';
import * as fromDashboard from './main/content/apps/dashboard/board/store/dashboard.reducer';
import * as fromStrategyCanvas from './main/content/apps/dashboard/strategy-canvas/store/strategy-canvas.reducer';
import * as fromGoalResult from './main/content/apps/result/goal-result/store/goal-result.reducer';
import * as fromProcessResult from './main/content/apps/result/process-result/store/process-result.reducer';
import * as fromKPIResult from './main/content/apps/result/kpi-result/store/kpi-result.reducer';
import * as fromDashboardSettings from './main/content/apps/dashboard/board-settings/store/board-settings.reducer';
import * as fromRole from './main/content/apps/administration/roles/store/role.reducer';
import * as fromFilter from './main/content/apps/filter/store/filter.reducer';
import * as fromDashSearch from './main/content/apps/dashboard/board/board-header/board-search-store/board-search-store.reducer';
import * as fromScrumboard from './main/content/apps/task/scrumboard/store/scrumboard.reducer';
import * as fromProcessDesigner from './main/content/apps/designers/process/designer/store/process-designer.reducer';
import * as fromKPIDiagram from './main/content/apps/designers/kpi/diagram/store/kpi-diagram.reducer';
import * as fromProcessDiagramSelection from './main/content/apps/designers/process/selection-dialog/process-diagram-selection/store/process-diagram-selection.reducer';
import * as fromSidePanel from './main/side-panel/store/side-panel.reducer';
import * as fromGlobalOptions from './main/content/apps/administration/global-options/store/global-options.reducer';
import * as fromFMSD from './main/content/apps/file-manager/file-manager-selection-dialog/store/file-manager-selection-dialog.reducer';
import * as fromPM from './main/content/apps/project-manager/store/project.reducer';
import * as fromPart from './main/content/apps/part/store/part.reducer';
import * as fromTool from './main/content/apps/tool/store/tool.reducer';
import * as fromInsp from './main/content/apps/result/inspection-result/store/inspection-result.reducer';
import * as fromInspEquipment from './main/content/apps/inspection-equipment/store/inspection-equipment.reducer';

import { FilterNameList } from './main/content/apps/filter/filter-modules-list';
import { GridsterData } from './main/content/apps/dashboard/board/models/widget.model';
import { taskFormFieldTypes, TaskStatus } from './main/content/apps/task/task.model';
import { DifferentiatorUtils } from './core/utils/differentiator.utils';
import { DocumentStore } from './main/content/apps/file-manager/document.model';
import { findSortProperty } from './main/content/apps/catalog/catalog/store/catalog.helper';
import { LicenseDetails, NamedLicenseStrategy } from './shared/license/licensedetails.model';
import { CharacteristicStatus, ProcessDiagramStatus, StepStatus, StepType } from './main/content/apps/designers/process/model/process-diagram.model';
import { OdooPaymentCycles, odooPaymentTranslations } from './main/content/apps/administration/license-upgrade/license-upgrade.model';
import {
  detectBinaryBlockCharacteristics,
  findBlockEndingCharacteristics,
} from './main/content/apps/dashboard/board/board-header/board-search-store/board-search-store.helper';
import { InspectionStepHeaderData } from './main/content/apps/designers/shared/step-requirements/model/step-requirement-data.model';
import { Authority } from './main/content/apps/administration/roles/role.model';
import { DashboardFlowType } from './main/content/apps/dashboard/board/models/board.model';
import { InspectionStatus, SyncStatus } from './main/content/apps/result/process-result/process-instance.model';
import cloneDeep from 'lodash/cloneDeep';
import { Progress } from './main/content/apps/result/inspection-result/instance-progress.model';
import { InspectionCharacteristicMetadata } from './core/components/characteristic/characteristic.model';
import { InspectionProcessMetadata } from './main/content/apps/designers/process/model/process-diagram.metadata';
import { PartRequiredPropertyKeyType } from './main/content/apps/designers/shared/step-requirements/model/part-requirement-property-key-type';

export interface State {
  router: fromRouter.RouterReducerState;
  authentication: fromAuthenticationPage.State;
  profile: fromProfile.State;
  license: fromLicense.State;
  licenseUpgrade: fromLicenseUpgrade.State;
  goalDiagram: fromGoalDiagram.State;
  goalDesigner: fromGoalDesigner.State;
  kpiDesigner: fromKPIDesigner.State;
  procDiagram: fromProcessDiagram.State;
  procDiagramSelection: fromProcessDiagramSelection.State;
  kpiDiagram: fromKPIDiagram.State;
  procResult: fromProcessResult.State;
  goalResult: fromGoalResult.State;
  kpiResult: fromKPIResult.State;
  dash: fromDashboard.State;
  dashSettings: fromDashboardSettings.State;
  file: fromFileManager.State;
  tm: fromTaskManger.State;
  u: fromUser.State;
  cat: fromCat.State;
  catDialog: fromCatDialog.State;
  procDesigner: fromProcessDesigner.State;
  role: fromRole.State;
  filter: fromFilter.State;
  dashse: fromDashSearch.State;
  sb: fromScrumboard.State;
  sp: fromSidePanel.State;
  globalOptions: fromGlobalOptions.State;
  sc: fromStrategyCanvas.State;
  fmsd: fromFMSD.State;
  pm: fromPM.State;
  part: fromPart.State;
  tool: fromTool.State;
  inspection: fromInsp.State;
  inspectionEquipment: fromInspEquipment.State;
}

export const reducers: ActionReducerMap<State> = {
  router: fromRouter.routerReducer,
  authentication: fromAuthenticationPage.authenticationPageReducer,
  profile: fromProfile.profileReducer,
  license: fromLicense.licenseManagerReducer,
  licenseUpgrade: fromLicenseUpgrade.licenseUpgradeReducer,
  goalDiagram: fromGoalDiagram.goalDiagramReducer,
  goalDesigner: fromGoalDesigner.goalDesignerReducer,
  kpiDesigner: fromKPIDesigner.kpiDesignerReducer,
  procDiagram: fromProcessDiagram.processDiagramReducer,
  kpiDiagram: fromKPIDiagram.kpiDiagramReducer,
  procDiagramSelection: fromProcessDiagramSelection.processDiagramSelectionReducer,
  procResult: fromProcessResult.processResultReducer,
  goalResult: fromGoalResult.goalResultReducer,
  kpiResult: fromKPIResult.kpiResultReducer,
  procDesigner: fromProcessDesigner.processDesignerReducer,
  tm: fromTaskManger.taskReducer,
  dash: fromDashboard.dashboardReducer,
  dashSettings: fromDashboardSettings.dashboardSettingsReducer,
  file: fromFileManager.fileManagerReducer,
  u: fromUser.userReducer,
  cat: fromCat.catReducer,
  catDialog: fromCatDialog.catDialogReducer,
  role: fromRole.RoleReducer,
  filter: fromFilter.filterReducer,
  dashse: fromDashSearch.dashboardSearchReducer,
  sb: fromScrumboard.scrumboardReducer,
  sp: fromSidePanel.sidePanelReducer,
  globalOptions: fromGlobalOptions.globalOptionsReducer,
  sc: fromStrategyCanvas.strategyCanvasReducer,
  fmsd: fromFMSD.fmsdReducer,
  pm: fromPM.projectManagementReducer,
  part: fromPart.partReducer,
  tool: fromTool.toolReducer,
  inspection: fromInsp.inspectionResultReducer,
  inspectionEquipment: fromInspEquipment.inspectionEquipmentReducer,
};

// Router state
export const getRouterState = createFeatureSelector<fromRouter.RouterReducerState>('router');

// Authentication state
export const getAuthenticationPageState = createFeatureSelector<fromAuthenticationPage.State>('authentication');
export const getLoginPageLoading = createSelector(getAuthenticationPageState, fromAuthenticationPage.getLoginPageLoading);
export const getAuthenticationRequestStatus = createSelector(getAuthenticationPageState, fromAuthenticationPage.getRequestStatus);
export const getAuthenticationTenantLogo = createSelector(getAuthenticationPageState, fromAuthenticationPage.getTenantLogo);

// Profile state
export const getProfileState = createFeatureSelector<fromProfile.State>('profile');
export const getActiveUser = createSelector(getProfileState, fromProfile.getActiveUser);
export const getActiveUserLicenseDetails = createSelector(getProfileState, fromProfile.getActiveUserLicenseDetails);
export const getActiveUserOptions = createSelector(getProfileState, fromProfile.getActiveUserOptions);
export const getActiveUserNavigationOptions = createSelector(getActiveUserOptions, options => options?.navigationOptions);
export const getLicenseMultilanguageEnabled = createSelector(
  getActiveUserLicenseDetails,
  (licenseDetails: LicenseDetails): boolean => licenseDetails?.license?.features?.multilanguage?.enabled,
);
export const getSelectedLanguage = createSelector(getProfileState, fromProfile.getSelectedLanguage);
export const getJwt = createSelector(getProfileState, fromProfile.getJwt);
export const getTenantName = createSelector(getProfileState, fromProfile.getTenantName);
export const getTenantId = createSelector(getProfileState, fromProfile.getTenantId);
export const getGlobalOptionsfromProfile = createSelector(getProfileState, fromProfile.getGlobalOptions);
export const getActiveMqAddress = createSelector(getProfileState, fromProfile.getActiveMqAddress);
export const getMqttAddress = createSelector(getProfileState, fromProfile.getMqttAddress);
export const getOutlierCoefficient = createSelector(getGlobalOptionsfromProfile, options => options?.inspectionOptions?.outlierCoefficient);
export const getReportServiceUrl = createSelector(getProfileState, fromProfile.getReportServiceUrl);
export const getBridgeDownloadUrl = createSelector(getProfileState, fromProfile.getBridgeDownloadUrl);
export const getActiveUserLastDashboardId = createSelector(getProfileState, fromProfile.getLastSelectedDashboardId);
export const getHasViewOnlyLicense = createSelector(getActiveUserLicenseDetails, ld => ld?.hasViewOnlyLicense);
export const getActiveUserFilter = createSelector(getProfileState, fromProfile.getActiveUserFilter);
export const getProductLine = createSelector(getProfileState, fromProfile.getProductLine);
export const getActiveUserFiltersForComponent = (filterName: FilterNameList, instanceId: string) =>
  createSelector(getActiveUserFilter, filter => {
    if (!filter) return null;
    return filter.find(f => f.filterName === filterName && (!instanceId || f.instanceId === instanceId)) || null;
  });

export const getMaxAllowedUsersForNamedLicense = createSelector(getActiveUserLicenseDetails, (licenseDetails: LicenseDetails): number => {
  return (licenseDetails?.license?.licenseStrategy as NamedLicenseStrategy)?.numberOfNamedUsers;
});

export const getNamedUsers = createSelector(getActiveUserLicenseDetails, (licenseDetails: LicenseDetails): number => {
  return (licenseDetails?.license?.licenseStrategy as NamedLicenseStrategy)?.namedUsers.length;
});

export const getNamedUserLimitReached = createSelector(getMaxAllowedUsersForNamedLicense, getNamedUsers, (maxAllowedUsers, namedUsers) => {
  return namedUsers >= maxAllowedUsers;
});

export const getActiveUserHasAnyAuthority = (authorities: Authority[]) =>
  createSelector(getActiveUser, user => {
    if (!authorities?.length) return true;
    if (!user) return false;

    const userAuthorities = new Set(user.authorities);

    return authorities.some(authority => userAuthorities.has(authority));
  });

export const getIsTenantAdmin = createSelector(getActiveUserHasAnyAuthority([Authority.GLOBAL_OPTIONS_DELETE]), isTenantAdmin => isTenantAdmin);

export const getMqttAddressData = createSelector(getMqttAddress, mqttAddress => {
  if (mqttAddress == null) return null;

  const [scheme, address, port] = mqttAddress.split(':');

  return {
    scheme: scheme,
    address: address.slice(2),
    port,
  };
});

export const getPartState = createFeatureSelector<fromPart.State>('part');
export const getParts = createSelector(getPartState, fromPart.getParts);
export const getPartsSelectedId = createSelector(getPartState, fromPart.getSelectedPartId);
export const getPartsLoading = createSelector(getPartState, fromPart.getPartsLoading);
export const getPartBeingCreated = createSelector(getPartState, fromPart.getPartBeingCreated);
export const getPartsPage = createSelector(getPartState, fromPart.getPartsPage);
export const getPartsSelectedPart = createSelector(
  getPartBeingCreated,
  getParts,
  getPartsSelectedId,
  (creatingPart, parts, partId) => creatingPart ?? parts.find(part => part.id === partId),
);
export const getPartViewOnly = createSelector(
  getActiveUserHasAnyAuthority([Authority.CATALOG_CREATE, Authority.CATALOG_UPDATE, Authority.CATALOG_DELETE]),
  canWriteCatalog => !canWriteCatalog,
);

export const getToolState = createFeatureSelector<fromTool.State>('tool');
export const getTools = createSelector(getToolState, fromTool.getTools);
export const getToolSelectedId = createSelector(getToolState, fromTool.getSelectedToolId);
export const getToolsLoading = createSelector(getToolState, fromTool.getToolsLoading);
export const getToolBeingCreated = createSelector(getToolState, fromTool.getToolBeingCreated);
export const getToolsPage = createSelector(getToolState, fromTool.getToolsPage);
export const getToolSelectedTool = createSelector(
  getToolBeingCreated,
  getTools,
  getToolSelectedId,
  (creatingTool, tools, toolId) => creatingTool ?? (tools ?? []).find(part => part.id === toolId),
);
export const getToolViewOnly = createSelector(
  getActiveUserHasAnyAuthority([Authority.CATALOG_CREATE, Authority.CATALOG_UPDATE, Authority.CATALOG_DELETE]),
  canWriteCatalog => !canWriteCatalog,
);

// Licence state
export const getLicenseMANAGERState = createFeatureSelector<fromLicense.State>('license');
export const getLicenseMANAGERLicenseDetails = createSelector(getLicenseMANAGERState, fromLicense.getActiveUserLicenseDetails);
export const getLicenseMANAGERLicenseDetailsLoading = createSelector(getLicenseMANAGERState, fromLicense.getActiveUserLicenseLoading);

// Odoo Subscription state
export const getOdooState = createFeatureSelector<fromLicenseUpgrade.State>('licenseUpgrade');
export const getOdooSubscription = createSelector(getOdooState, fromLicenseUpgrade.getOdooSubscription);
export const getOdooSubscriptionLoading = createSelector(getOdooState, fromLicenseUpgrade.getOdooSubscriptionLoading);
export const getLicensePackages = createSelector(getOdooState, fromLicenseUpgrade.getLicensePackages);
export const getPaymentCycle = createSelector(getOdooState, fromLicenseUpgrade.getPaymentCycle);
export const getPaymentCycleText = createSelector(getPaymentCycle, cycle => odooPaymentTranslations[cycle]);
export const getSelectedLicensePackageDetails = createSelector(getOdooState, fromLicenseUpgrade.getSelectedLicensePackageDetails);

export const getOdooTotalPrice = createSelector(getPaymentCycle, getSelectedLicensePackageDetails, (paymentCycle, selectedLicensePackageDetails): number => {
  if (paymentCycle === OdooPaymentCycles.Yearly) {
    return 12 * selectedLicensePackageDetails?.amountUsers * selectedLicensePackageDetails?.selectedLicensePackage?.priceYearly;
  }
  return selectedLicensePackageDetails?.amountUsers * selectedLicensePackageDetails?.selectedLicensePackage?.priceMonthly;
});

// Side Panel State
export const getSidePanelState = createFeatureSelector<fromSidePanel.State>('sp');
export const getSidePanelNotificationQueryParams = createSelector(getSidePanelState, fromSidePanel.getNotificationQueryParams);
export const getSidePanelLoadingNotifications = createSelector(getSidePanelState, fromSidePanel.getLoadingNotifications);
export const getSidePanelNotificationCount = createSelector(getSidePanelState, fromSidePanel.getNotificationCount);
export const getSidePanelNotificationList = createSelector(getSidePanelState, fromSidePanel.getNotificationList);
export const getSidePanelUnreadNotificationCount = createSelector(getSidePanelState, fromSidePanel.getUnreadNotificationCount);
export const getSidePanelCurrentUser = createSelector(getSidePanelState, fromSidePanel.getCurrentUser);
export const getSidePanelIsUpdatingPassword = createSelector(getSidePanelState, fromSidePanel.getIsUpdatingPassword);
export const getSidePanelCurrentUserUpdate = createSelector(getSidePanelState, fromSidePanel.getCurrentUserUpdate);
export const getSidePanelUserInfoLoading = createSelector(getSidePanelState, fromSidePanel.getUserUpdateLoading);
export const getApplicationBuildInformation = createSelector(getSidePanelState, fromSidePanel.getApplicationBuildInfo);

// Global Options State
export const getGlobalOptionsState = createFeatureSelector<fromGlobalOptions.State>('globalOptions');
export const getGlobalOptions = createSelector(getGlobalOptionsState, fromGlobalOptions.getGlobalOptions);
export const getGlobalOptionsLoading = createSelector(getGlobalOptionsState, fromGlobalOptions.getGlobalOptionsLoading);

// Process diagram state
export const getProcessDiagramState = createFeatureSelector<fromProcessDiagram.State>('procDiagram');
export const getProcessDiagramList = createSelector(getProcessDiagramState, fromProcessDiagram.getProcessDiagramList);
export const getProcessDiagramQueryParams = createSelector(getProcessDiagramState, fromProcessDiagram.getQueryParams);
export const getSelectedProcessDiagram = createSelector(getProcessDiagramState, fromProcessDiagram.getSelectedProcessDiagram);
export const getProcessDiagramLoading = createSelector(getProcessDiagramState, fromProcessDiagram.getProcessDiagramLoading);
export const getRootProcessDiagramId = createSelector(getProcessDiagramState, fromProcessDiagram.getRootProcessDiagramId);
export const getProcessDiagramListSortOrder = createSelector(getProcessDiagramState, fromProcessDiagram.getProcessDiagramCurrentSortOrder);
export const getProcessDiagramBreadcrumb = createSelector(getProcessDiagramState, fromProcessDiagram.getProcessDiagramBreadcrumb);
export const getProcessDiagramViewMode = createSelector(getProcessDiagramState, fromProcessDiagram.getViewOnlyMode);

export const getProcessDiagramSelectionState = createFeatureSelector<fromProcessDiagramSelection.State>('procDiagramSelection');
export const getProcessDiagramSelectionList = createSelector(getProcessDiagramSelectionState, fromProcessDiagramSelection.getProcessDiagramList);
export const getProcessDiagramSelectionQueryParams = createSelector(getProcessDiagramSelectionState, fromProcessDiagramSelection.getQueryParams);
export const getProcessDiagramSelectionLoading = createSelector(getProcessDiagramSelectionState, fromProcessDiagramSelection.getLoading);
export const getRootProcessDiagraSelectionId = createSelector(getProcessDiagramSelectionState, fromProcessDiagramSelection.getRootProcessDiagramId);
export const getProcessDiagramSelectionBreadcrumb = createSelector(getProcessDiagramSelectionState, fromProcessDiagramSelection.getProcessDiagramBreadcrumb);

export const getProcessDesignerState = createFeatureSelector<fromProcessDesigner.State>('procDesigner');
export const getProcessDesignerSelectedStep = createSelector(getProcessDesignerState, fromProcessDesigner.getProcessDiagramSelectedStep);
export const getProcessDesignerLoadedSteps = createSelector(getProcessDesignerState, fromProcessDesigner.getProcessDiagramLoadedSteps);
export const getSelectedProcessDesigner = createSelector(getProcessDesignerState, fromProcessDesigner.getSelectedProcessDesigner);
export const getProcessDesignerCreatingStep = createSelector(getProcessDesignerState, fromProcessDesigner.getProcessDiagramCreatingStep);
export const getSelectedProcessDesignerViewMode = createSelector(getSelectedProcessDesigner, diagram => {
  return diagram.status === ProcessDiagramStatus.DONE || diagram.status === ProcessDiagramStatus.REVOKED || diagram.status === ProcessDiagramStatus.RELEASED;
});
export const getProcessDesignerLicenseViewMode = createSelector(getProcessDesignerState, fromProcessDesigner.getProcessDiagramLicenseViewMode);
export const getProcessDesignerViewMode = createSelector(
  getProcessDesignerLicenseViewMode,
  getSelectedProcessDesignerViewMode,
  (selectedProcessDesignerViewMode, licenseViewMode) => selectedProcessDesignerViewMode || licenseViewMode,
);
export const getIsProcessDiagramEmpty = createSelector(getSelectedProcessDesigner, diagram => !(diagram?.steps?.length > 0));
export const getProcessDesignerLoading = createSelector(getProcessDesignerState, fromProcessDesigner.getProcessDesignerLoading);

export const getGoalDesignerState = createFeatureSelector<fromGoalDesigner.State>('goalDesigner');
export const getGoalDesignerSelectedStep = createSelector(getGoalDesignerState, fromGoalDesigner.getGoalDiagramSelectedStep);
export const getGoalDesignerLoadedSteps = createSelector(getGoalDesignerState, fromGoalDesigner.getGoalDiagramLoadedSteps);
export const getSelectedGoalDesigner = createSelector(getGoalDesignerState, fromGoalDesigner.getSelectedGoalDesigner);
export const getGoalDesignerCreatingStep = createSelector(getGoalDesignerState, fromGoalDesigner.getGoalDiagramCreatingStep);
export const getSelectedGoalDesignerViewMode = createSelector(getSelectedGoalDesigner, diagram => {
  return diagram.status === ProcessDiagramStatus.ACTIVE || diagram.status === ProcessDiagramStatus.DONE;
});
export const getGoalDesignerLicenseViewMode = createSelector(getGoalDesignerState, fromGoalDesigner.getGoalDesignerViewMode);
export const getGoalDesignerViewMode = createSelector(
  getSelectedGoalDesignerViewMode,
  getGoalDesignerLicenseViewMode,
  (selectedGoalViewMode, licenseViewMode) => selectedGoalViewMode || licenseViewMode,
);
export const getIsGoalDiagramEmpty = createSelector(getSelectedGoalDesigner, diagram => !(diagram?.steps?.length > 0));
export const getGoalDesignerLoading = createSelector(getGoalDesignerState, fromGoalDesigner.getGoalDesignerLoading);

export const getGoalDiagramState = createFeatureSelector<fromGoalDiagram.State>('goalDiagram');
export const getGoalDiagramList = createSelector(getGoalDiagramState, fromGoalDiagram.getGoalDiagramList);
export const getGoalDiagramQueryParams = createSelector(getGoalDiagramState, fromGoalDiagram.getQueryParams);
export const getGoalDiagramSelected = createSelector(getGoalDiagramState, fromGoalDiagram.getSelectedGoalDiagram);
export const getGoalDiagramLoading = createSelector(getGoalDiagramState, fromGoalDiagram.getGoalDiagramLoading);
export const getGoalDiagramRootId = createSelector(getGoalDiagramState, fromGoalDiagram.getRootGoalDiagramId);
export const getGoalDiagramListSortOrder = createSelector(getGoalDiagramState, fromGoalDiagram.getGoalDiagramCurrentSortOrder);
export const getGoalDiagramBreadcrumb = createSelector(getGoalDiagramState, fromGoalDiagram.getGoalDiagramBreadcrumb);
export const getGoalDiagramViewMode = createSelector(getGoalDiagramState, fromGoalDiagram.getGoalDiagramViewMode);

export const getProjectManagementState = createFeatureSelector<fromPM.State>('pm');

export const getProjectList = createSelector(getProjectManagementState, fromPM.getProjectList);
export const getProjectQueryParams = createSelector(getProjectManagementState, fromPM.getProjectQueryParams);
export const getProjectSelected = createSelector(getProjectManagementState, fromPM.getProjectSelected);
export const getProjectLoading = createSelector(getProjectManagementState, fromPM.getProjectLoading);
export const getProjectRootId = createSelector(getProjectManagementState, fromPM.getRootProjectId);
export const getProjectCurrentSort = createSelector(getProjectManagementState, fromPM.getProjectCurrentSortOrder);
export const getProjectBreadcrumb = createSelector(getProjectManagementState, fromPM.getProjectBreadcrumb);
export const getProjectViewMode = createSelector(getProjectManagementState, fromPM.getProjectViewMode);

export const getKPIDiagramState = createFeatureSelector<fromKPIDiagram.State>('kpiDiagram');
export const getKPIDiagramList = createSelector(getKPIDiagramState, fromKPIDiagram.getKPIDiagramList);
export const getKPIDiagramQueryParams = createSelector(getKPIDiagramState, fromKPIDiagram.getQueryParams);
export const getKPIDiagramSelected = createSelector(getKPIDiagramState, fromKPIDiagram.getSelectedKPIDiagram);
export const getKPIDiagramLoading = createSelector(getKPIDiagramState, fromKPIDiagram.getKPIDiagramLoading);
export const getKPIDiagramRootId = createSelector(getKPIDiagramState, fromKPIDiagram.getRootKPIDiagramId);
export const getKPIDiagramListSortOrder = createSelector(getKPIDiagramState, fromKPIDiagram.getKPIDiagramCurrentSortOrder);
export const getKPIDiagramBreadcrumb = createSelector(getKPIDiagramState, fromKPIDiagram.getKPIDiagramBreadcrumb);
export const getKPIDiagramViewMode = createSelector(getKPIDiagramState, fromKPIDiagram.getViewMode);

// KPI designer state
export const getKPIDesignerState = createFeatureSelector<fromKPIDesigner.State>('kpiDesigner');
export const getKPIDesignerSelectedStep = createSelector(getKPIDesignerState, fromKPIDesigner.getKPIDiagramSelectedStep);
export const getKPIDesignerLoadedSteps = createSelector(getKPIDesignerState, fromKPIDesigner.getKPIDiagramLoadedSteps);
export const getSelectedKPIDesigner = createSelector(getKPIDesignerState, fromKPIDesigner.getSelectedKPIDesigner);
export const getKPIDesignerCreatingStep = createSelector(getKPIDesignerState, fromKPIDesigner.getKPIDiagramCreatingStep);
export const getSelectedKPIDesignerViewMode = createSelector(getSelectedKPIDesigner, diagram => {
  return (
    diagram.status === ProcessDiagramStatus.DONE ||
    diagram.status === ProcessDiagramStatus.COMPLETED ||
    diagram.status === ProcessDiagramStatus.REVOKED ||
    diagram.status === ProcessDiagramStatus.CANCELED ||
    diagram.status === ProcessDiagramStatus.RELEASED
  );
});
export const getKPIlDesignerLicenseViewMode = createSelector(getKPIDesignerState, fromKPIDesigner.getKPIDiagramViewMode);
export const getKPIDesignerViewMode = createSelector(
  getSelectedKPIDesignerViewMode,
  getKPIlDesignerLicenseViewMode,
  (selectedKPIViewMode, licenseViewMode) => selectedKPIViewMode || licenseViewMode,
);
export const getIsKPIDiagramEmpty = createSelector(getSelectedKPIDesigner, diagram => !(diagram?.steps?.length > 0));
export const getKPIDesignerLoading = createSelector(getKPIDesignerState, fromKPIDesigner.getKPIDesignerLoading);

// Process diagram result state
export const getInspectionResultState = createFeatureSelector<fromInsp.State>('inspection');
export const getInspectionResultList = createSelector(getInspectionResultState, fromInsp.getInspectionResultList);
export const getInspectionResultQueryParams = createSelector(getInspectionResultState, fromInsp.getQueryParams);
export const getInspectionResultSelectedResult = createSelector(getInspectionResultState, fromInsp.getSelectedInspectionResult);
export const getInspectionResultLoading = createSelector(getInspectionResultState, fromInsp.getInspectionResultLoading);
export const getInspectionResultSortOrder = createSelector(getInspectionResultState, fromInsp.getInspectionResultSortOrder);
export const getInspectionResultViewMode = createSelector(getInspectionResultState, fromInsp.getViewOnlyMode);

// Process diagram result state
export const getProcessResultState = createFeatureSelector<fromProcessResult.State>('procResult');
export const getProcessResultList = createSelector(getProcessResultState, fromProcessResult.getProcessResultList);
export const getProcessResultQueryParams = createSelector(getProcessResultState, fromProcessResult.getQueryParams);
export const getSelectedProcessResult = createSelector(getProcessResultState, fromProcessResult.getSelectedProcessResult);
export const getProcessResultLoading = createSelector(getProcessResultState, fromProcessResult.getProcessResultLoading);
export const getRootProcessResultId = createSelector(getProcessResultState, fromProcessResult.getRootProcessResultId);
export const getRootProcessResultDiagramId = createSelector(getProcessResultState, fromProcessResult.getRootProcessResultDiagram);
export const getProcessResultSortOrder = createSelector(getProcessResultState, fromProcessResult.getProcessResultSortOrder);
export const getProcessResultViewMode = createSelector(getProcessResultState, fromProcessResult.getViewOnlyMode);

// Goal diagram result state
export const getGoalResultState = createFeatureSelector<fromGoalResult.State>('goalResult');
export const getGoalResultList = createSelector(getGoalResultState, fromGoalResult.getGoalResultList);
export const getGoalResultQueryParams = createSelector(getGoalResultState, fromGoalResult.getQueryParams);
export const getSelectedGoalResult = createSelector(getGoalResultState, fromGoalResult.getSelectedGoalResult);
export const getGoalResultLoading = createSelector(getGoalResultState, fromGoalResult.getGoalResultLoading);
export const getRootGoalResultId = createSelector(getGoalResultState, fromGoalResult.getRootGoalResultId);
export const getRootGoalResultDiagramId = createSelector(getGoalResultState, fromGoalResult.getRootGoalResultDiagram);
export const getGoalResultSortOrder = createSelector(getGoalResultState, fromGoalResult.getGoalResultSortOrder);

// KPI diagram result state
export const getKPIResultState = createFeatureSelector<fromKPIResult.State>('kpiResult');
export const getKPIResultList = createSelector(getKPIResultState, fromKPIResult.getKPIResultList);
export const getKPIResultQueryParams = createSelector(getKPIResultState, fromKPIResult.getQueryParams);
export const getSelectedKPIResult = createSelector(getKPIResultState, fromKPIResult.getSelectedKPIResult);
export const getKPIResultLoading = createSelector(getKPIResultState, fromKPIResult.getKPIResultLoading);
export const getRootKPIResultId = createSelector(getKPIResultState, fromKPIResult.getRootKPIResultId);
export const getRootKPIResultDiagramId = createSelector(getKPIResultState, fromKPIResult.getRootKPIResultDiagram);
export const getKPIResultSortOrder = createSelector(getKPIResultState, fromKPIResult.getKPIResultSortOrder);

// Filters state
export const getFilterState = createFeatureSelector<fromFilter.State>('filter');
export const getModuleFilters = (filterName: FilterNameList) =>
  createSelector(getFilterState, fromFilter.getModuleFilters, filterState => filterState.moduleFilters[filterName] || []);
export const getFilterList = createSelector(getFilterState, fromFilter.getFilterList);
export const getFiltersLoaded = (filterName: FilterNameList) =>
  createSelector(getFilterState, fromFilter.getFiltersLoaded, filterState => filterState.filtersLoaded[filterName] || false);
//getFirstElement
export const getSelectedFilter = (filterName: FilterNameList) =>
  createSelector(getFilterState, fromFilter.getSelectedFilter, filterState => filterState.selectedFilter[filterName] || null);
export const getActiveFilter = (filterName: FilterNameList) =>
  createSelector(getFilterState, fromFilter.getActiveFilter, filterState => filterState.activeFilter[filterName] || null);
export const getActiveFilterForModule = (filterName: FilterNameList, instanceId?: string) =>
  createSelector(getActiveFilter(filterName), filters => {
    if (!filters) return null;

    return instanceId ? filters.find(f => f.instanceId === instanceId) : filters[0];
  });

export const getSearchText = (filterName: FilterNameList) =>
  createSelector(getFilterState, fromFilter.getSearchText, filterState => filterState.searchText[filterName] || '');
export const getMetadata = (filterName: FilterNameList) =>
  createSelector(getFilterState, fromFilter.getMetadata, filterState => filterState.filterMetadata[filterName] || null);
export const getMetadataLoaded = createSelector(getFilterState, fromFilter.getMetadataLoaded);
export const getWidgetFilters = (filterName: FilterNameList) =>
  createSelector(getFilterState, fromFilter.getWidgetFilter, filterState => filterState.widgetFilters[filterName] || null);

// Task manager state
export const getTmState = createFeatureSelector<fromTaskManger.State>('tm');
export const getTaskList = createSelector(getTmState, fromTaskManger.getTaskList);
export const getSelectedTask = createSelector(getTmState, fromTaskManger.getSelectedTask);
export const getTaskEditMode = createSelector(getTmState, fromTaskManger.getEditMode);
export const getTaskOrderBy = createSelector(getTmState, fromTaskManger.getOrderBy);
export const getTaskTotalItemsCount = createSelector(getTmState, fromTaskManger.getTotalItemsCount);
export const getTaskQueryParams = createSelector(getTmState, fromTaskManger.getQueryParams);
export const getTaskLoading = createSelector(getTmState, fromTaskManger.getLoading);
export const getTaskFormSubscriptionId = createSelector(getTmState, fromTaskManger.getFormSubscriptionId);
export const getLastTaskChanges = createSelector(getTmState, fromTaskManger.getLastTaskChanges);
export const getTaskHistory = createSelector(getTmState, fromTaskManger.getTaskHistory);
export const getTaskOnlyViewMode = createSelector(
  getActiveUserHasAnyAuthority([Authority.TASK_CREATE, Authority.TASK_UPDATE, Authority.TASK_DELETE]),
  canWriteTasks => !canWriteTasks,
);
export const getCanCreateTask = createSelector(getActiveUserHasAnyAuthority([Authority.TASK_CREATE]), canWriteTasks => canWriteTasks);
export const getCanUpdateOrDeleteTask = createSelector(getActiveUserHasAnyAuthority([Authority.TASK_UPDATE, Authority.TASK_DELETE]), canWriteTasks => canWriteTasks);
export const getTaskScheduledJob = createSelector(getTmState, fromTaskManger.getSelectedScheduledJob);
export const getTaskJobRecurrencePattern = createSelector(getTaskScheduledJob, scJob => scJob?.recurrencePattern);
export const getTaskHistoryChanges = createSelector(getTaskHistory, historyList => {
  const taskFields = Object.keys(taskFormFieldTypes);

  return historyList.map((historyEntry, index) => {
    if (index === 0) return historyEntry;

    return {
      ...historyEntry,
      timestamp: historyList[index - 1].timestamp,
      editor: historyList[index - 1].editor,
      changes: Object.keys(DifferentiatorUtils.differentiate(historyList[index - 1].task, historyEntry.task))
        .filter(field => taskFields.includes(field))
        .map(field => ({
          property: field,
          type: taskFormFieldTypes[field],
          oldValue: historyList[index].task[field],
          newValue: historyList[index - 1].task[field],
        })),
    };
  });
});
export const getTaskHistoryQueryParams = createSelector(getTmState, fromTaskManger.getHistoryQueryParams);
export const getTaskHistoryTaskEntryCount = createSelector(getTmState, fromTaskManger.getHistoryTaskEntryCount);

export const getScrumboardState = createFeatureSelector<fromScrumboard.State>('sb');
export const getScrumboardTasks = createSelector(getScrumboardState, fromScrumboard.getScrumboardTasks);
export const getScrumboardTasksLoading = createSelector(getScrumboardState, fromScrumboard.getScrumboardTasksLoading);
export const getScrumboardLoading = createSelector(getScrumboardState, fromScrumboard.getLoading);
export const getScrumBoardTotalItemsCount = createSelector(getScrumboardState, fromScrumboard.getTotalItemsCount);
export const getScrumboardLastTaskChanges = createSelector(getScrumboardState, fromScrumboard.getLastTaskChanges);
export const getScrumboardTaskUpdating = createSelector(getScrumboardState, fromScrumboard.getTaskUpdating);
export const getScrumboardSelectedTask = createSelector(getScrumboardState, fromScrumboard.getSelectedTask);
export const getScrumboardEditMode = createSelector(getScrumboardState, fromScrumboard.getEditMode);
export const getScrumboardFormSubscriptionId = createSelector(getScrumboardState, fromScrumboard.getFormSubscriptionId);
export const getScrumboardOrderBy = createSelector(getScrumboardState, fromScrumboard.getOrderBy);
export const getScrumboardLastDragData = createSelector(getScrumboardState, fromScrumboard.getLastTaskDragData);
export const getAwaitingDragResponse = createSelector(getScrumboardState, fromScrumboard.getAwaitingDragResponse);
export const getScrumboardTaskList = createSelector(getScrumboardTasks, taskCols => Object.values(taskCols).flatMap(tasks => tasks));
export const getScrumboardScheduledJob = createSelector(getScrumboardState, fromScrumboard.getSelectedScheduledJob);
export const getScrumboardSourceProject = createSelector(getScrumboardState, fromScrumboard.getSourceProject);
export const getScrumboardTasksPage = createSelector(getScrumboardState, fromScrumboard.getScrumboardTaskPage);
export const getScrumboardViewMode = createSelector(
  getActiveUserHasAnyAuthority([Authority.TASK_CREATE, Authority.TASK_UPDATE, Authority.TASK_DELETE]),
  canWriteTasks => !canWriteTasks,
);
//has only ViewMode if authorotiy has only Task_Read but if has also Task_Read and Task_Update and delete then its not viewMode
export const getScrumboardOnlyViewMode = createSelector(
  getActiveUserHasAnyAuthority([Authority.TASK_CREATE, Authority.TASK_UPDATE, Authority.TASK_DELETE]),
  canWriteTasks => !canWriteTasks,
);

export const getScrumboardJobRecurrencePattern = createSelector(getScrumboardScheduledJob, scJob => scJob?.recurrencePattern);
export const getTaskListOfStatus = (taskStatus: TaskStatus) => createSelector(getScrumboardTasks, tasks => [...(tasks[taskStatus] || [])]);
export const getScrumboardColumnPage = (taskStatus: TaskStatus) => createSelector(getScrumboardTasksPage, pages => pages[taskStatus] || 0);
export const getTasksLoadingOfStatus = (taskStatus: TaskStatus) => createSelector(getScrumboardTasksLoading, statuses => statuses[taskStatus]);

// Dashboard manager state
export const getDashboardState = createFeatureSelector<fromDashboard.State>('dash');
export const getDashboardList = createSelector(getDashboardState, fromDashboard.getDashboardList);
export const getDashboardSelectedId = createSelector(getDashboardState, fromDashboard.getSelectedDashboardId);
export const getDashboardParentId = createSelector(getDashboardState, fromDashboard.getParentDashboardId);
export const getParentDashboard = createSelector(getDashboardState, fromDashboard.getParentDashboard);
export const getDashboardSelected = createSelector(getDashboardList, getDashboardSelectedId, (dashList, selectedId) =>
  (dashList ?? []).find(({ id }) => id === selectedId),
);
export const getDashboardSelectedDashboard = createSelector(getDashboardSelected, dashboard => {
  if (!dashboard) return null;
  return {
    ...dashboard,
    widgets: (dashboard.widgets ?? []).slice().sort((w1, w2) => {
      if (w1.gridsterData.y === w2.gridsterData.y) return w1.gridsterData.x < w2.gridsterData.x ? -1 : 1;
      return w1.gridsterData.y < w2.gridsterData.y ? -1 : 1;
    }),
  };
});
export const getDashboardLoading = createSelector(getDashboardState, fromDashboard.getLoading);
export const getDashboardConfigMode = createSelector(getDashboardState, fromDashboard.getConfigMode);
export const getDashboardViewOnlyMode = createSelector(getDashboardState, fromDashboard.getViewOnlyMode);
export const getDashboardSelectedDashboardIndex = createSelector(getDashboardList, getDashboardSelectedDashboard, (dashboards, dash) =>
  dashboards?.findIndex(d => d.id === dash?.id),
);
export const getSelectedDashboardGridsterItems = createSelector(getDashboardSelectedDashboard, dashboard =>
  (dashboard?.widgets || []).map(
    widget => new GridsterData(widget.instanceId, widget.type, widget.gridsterData.x, widget.gridsterData.y, widget.gridsterData.rows, widget.gridsterData.cols),
  ),
);

export const getParentDashboardFlowData = createSelector(getParentDashboard, parentDashboard => parentDashboard?.flow);

// Strategy canvas state
export const getStrategyCanvasState = createFeatureSelector<fromStrategyCanvas.State>('sc');
export const getSCDashboardList = createSelector(getStrategyCanvasState, fromStrategyCanvas.getDashboardList);
export const getSCSelectedDashboard = createSelector(getStrategyCanvasState, fromStrategyCanvas.getSelectedDashboard);
export const getSCDashboardLoading = createSelector(getStrategyCanvasState, fromStrategyCanvas.getLoading);
export const getSCViewOnly = createSelector(getStrategyCanvasState, fromStrategyCanvas.getViewOnlyMode);
export const getSCDashboardConfigMode = createSelector(getStrategyCanvasState, fromStrategyCanvas.getConfigMode);
export const getSCSelectedDashboardIndex = createSelector(getSCDashboardList, getSCSelectedDashboard, (dashboards, dash) =>
  dashboards?.findIndex(d => d.id === dash?.id),
);
export const getSCSelectedDashboardGridsterItems = createSelector(getSCSelectedDashboard, dashboard =>
  (dashboard?.widgets || [])
    .map(widget => new GridsterData(widget.instanceId, widget.type, widget.gridsterData.x, widget.gridsterData.y, widget.gridsterData.rows, widget.gridsterData.cols))
    .sort((gd1, gd2) => {
      if (gd1.x === gd2.x) return gd1.y < gd2.y ? 1 : -1;
      return gd1.x < gd2.x ? 1 : -1;
    }),
);

// Dashboard Search state
export const getDashboardSearchState = createFeatureSelector<fromDashSearch.State>('dashse');
export const getSearchProcessInstanceDash = createSelector(getDashboardSearchState, fromDashSearch.getSearchProcessInstance);
export const getProcessInstanceLoading = createSelector(getDashboardSearchState, fromDashSearch.getProcessInstanceLoading);
export const getSearchStepId = createSelector(getDashboardSearchState, fromDashSearch.getSearchStepId);
export const getSearchCharacteristicIdDash = createSelector(getDashboardSearchState, fromDashSearch.getSearchCharacteristicId);
export const getSearchLastSubmittedDocuments = createSelector(getDashboardSearchState, fromDashSearch.getLastSubmittedDocuments);
export const getSearchCharacteristicHistory = createSelector(getDashboardSearchState, fromDashSearch.getSearchCharacteristicHistory);
export const getSearchValueMessageSubmitting = createSelector(getDashboardSearchState, fromDashSearch.getValueMessageSubmitting);
export const getSearchCharacteristicHistoryLoading = createSelector(getDashboardSearchState, fromDashSearch.getSearchCharacteristicHistoryLoading);
export const getSearchIndexInSampleSelected = createSelector(getDashboardSearchState, fromDashSearch.getSearchIndexInSampleSelected);
export const getMyBridges = createSelector(getDashboardSearchState, fromDashSearch.getMyBridges);
export const getCharacteristicSampleData = createSelector(getDashboardSearchState, fromDashSearch.getSelectedCharacteristicSampleData);
export const getInstanceCharacteristicSampleData = createSelector(getDashboardSearchState, fromDashSearch.getInstanceCharacteristicSampleData);
export const getSelectedSampleNumber = createSelector(getDashboardSearchState, fromDashSearch.getSelectedSampleNumber);
export const getSampleData = createSelector(getDashboardSearchState, fromDashSearch.getSampleData);
export const loadingSubmitBulkMessage = createSelector(getDashboardSearchState, fromDashSearch.getLoadingSubmitBulkMessage);
export const getProcessInstanceInspectionType = createSelector(
  getSearchProcessInstanceDash,
  instance => (instance?.diagramInstance.metadata as InspectionProcessMetadata)?.inspectionType,
);
export const getLastSubmittedSerialNumber = createSelector(getDashboardSearchState, fromDashSearch.getLastSubmittedSerialNumber);

export const getHistoriesForIndexInSample = createSelector(getDashboardSearchState, fromDashSearch.getHistoriesForIndexInSample);

export const getSerialNumberForCurrentIndexInSample = createSelector(getHistoriesForIndexInSample, historiesForIndexInSample => {
  return historiesForIndexInSample.length > 0 ? historiesForIndexInSample[historiesForIndexInSample.length - 1].serialNumber : null;
});

export const getLastSubmittedBatchNumber = createSelector(getSampleData, sampleData => {
  if (sampleData.length === 0) return null;
  const lastSample = sampleData.find(({ sampleNumber }) => sampleNumber === Math.max(...sampleData.map(({ sampleNumber }) => sampleNumber)));
  return lastSample?.sampleRequirements?.BATCH;
});

export const getMaxCharSampleData = createSelector(getCharacteristicSampleData, csd => {
  // eslint-disable-next-line no-unsafe-optional-chaining
  const maxSample = Math.max(...(csd ?? [])?.map(({ sampleNumber }) => sampleNumber));
  return csd.find(({ sampleNumber }) => sampleNumber === maxSample);
});

export const getSearchProcessDiagram = createSelector(getSearchProcessInstanceDash, instance => instance?.diagramInstance);
export const getSearchProcessResponsible = createSelector(getSearchProcessDiagram, diagram => diagram?.responsible);
export const getSearchStep = createSelector(getSearchProcessDiagram, getSearchStepId, getInstanceCharacteristicSampleData, (diagram, stepId, instanceSampleData) => {
  const step = diagram?.steps?.find(({ id }) => id === stepId);
  if (step == null) return step;

  const ous = {
    ...step,
    characteristics: (step.characteristics ?? []).map(characteristic => ({
      ...characteristic,
      sampleData: instanceSampleData[characteristic.id],
    })),
  };

  return ous;
});

export const getSearchProcessInstanceSynced = createSelector(getSearchProcessInstanceDash, instance => instance?.sync.syncStatus != SyncStatus.NOT_READY);
export const getSearchCharacteristicDash = createSelector(getSearchStep, getSearchCharacteristicIdDash, (step, characteristicId) =>
  step?.characteristics?.find(({ id }) => id === characteristicId),
);

export const getSearchInstanceHasDecisionStep = createSelector(getSearchProcessDiagram, diagram => {
  return diagram?.steps.some(({ type }) => type === StepType.INSPECTION_DECISION);
});

export const getSearchActiveSampleNumber = createSelector(getSampleData, getSelectedSampleNumber, (sampleData, sampleNumber) => {
  if (!sampleData) return null;
  if (sampleNumber) return sampleNumber;
  return Math.max(...sampleData.map(({ sampleNumber }) => sampleNumber), 1);
});

export const getSelectedCharacteristicSample = createSelector(getSearchActiveSampleNumber, getCharacteristicSampleData, (currentSample, charSampleData) => {
  if (currentSample == null || !charSampleData?.length) return null;

  return charSampleData.find(({ sampleNumber }) => sampleNumber === currentSample);
});

export const getSelectedSample = createSelector(getSampleData, getSearchActiveSampleNumber, (sampleData, selectedSampleNumber) => {
  if (sampleData == null) return null;

  return sampleData.find(({ sampleNumber }) => sampleNumber === selectedSampleNumber);
});

export const getSearchCharacteristicHistoryForSample = createSelector(getSelectedSample, getSearchCharacteristicHistory, (sample, historyList) =>
  historyList.filter(historyEntry => historyEntry.sampleNumber === sample?.sampleNumber),
);

export const getSelectedSampleStatistics = createSelector(getSelectedCharacteristicSample, selectedSampleData => selectedSampleData?.qmStatistic);

export const getSearchIsInstanceUnlocked = createSelector(getSearchProcessDiagram, getActiveUser, (diagram, user) => {
  if (!diagram) return true;
  return !diagram?.processInstanceData?.instanceLock.locked || user?.id === diagram?.processInstanceData?.instanceLock.executor?.id;
});

export const getStepMaxSample = createSelector(getSampleData, sampleData => {
  if (sampleData.length == 0) return 0;
  return Math.max(...(sampleData ?? []).map(({ sampleNumber }) => sampleNumber), 1);
});

export const getSearchIsCurrentUserInstanceExecutor = createSelector(getSearchProcessDiagram, getActiveUser, (diagram, user) => {
  if (!diagram) return false;
  return user?.id === diagram?.processInstanceData?.instanceLock.executor?.id;
});

export const getSearchCanInputCurrentStep = createSelector(getSearchProcessInstanceDash, getSearchProcessDiagram, getSearchStep, (instance, diagram, step) => {
  if (instance?.status === InspectionStatus.PAUSED) return false;
  if (!step || !diagram) return false;

  const lastDoneStep = diagram.steps?.map(({ status }) => status).lastIndexOf(StepStatus.DONE);
  const selectedStepIndex = diagram.steps?.findIndex(({ id }) => id === step.id);

  return lastDoneStep + 1 >= selectedStepIndex;
});

export const getHasIdentityCheck = createSelector(getSearchProcessDiagram, diagram => {
  return diagram?.steps.some(({ type }) => type === StepType.IDENT_INSPECTION);
});

export const getAreStepValuesEntered = createSelector(getSearchStep, step => {
  return (step?.characteristics ?? []).every(char => {
    return char.sampleData?.exceptionId != null || char?.sampleData?.progress?.inputted === char?.sampleData?.progress?.planned;
  });
});

export const getSearchCharacteristicComments = createSelector(getSearchCharacteristicHistory, history => history?.map(entry => entry.comment).filter(Boolean));

export const getSearchInstanceExecutor = createSelector(getSearchProcessDiagram, diagram => diagram?.processInstanceData?.instanceLock.executor);

export const getSearchHasInstanceLock = createSelector(getSearchProcessDiagram, diagram => !!diagram?.processInstanceData?.instanceLock.locked);

export const getSearchDataDocuments = createSelector(
  getSearchProcessDiagram,
  getSearchCharacteristicDash,
  getSearchLastSubmittedDocuments,
  getSearchCharacteristicHistory,
  (diagram, char, inputtedDocs, history) => [
    ...(diagram?.documentList ?? []),
    ...(diagram?.steps?.flatMap(step => step?.documentList ?? []) ?? []),
    ...(diagram?.steps?.flatMap(step => (step?.headerData as InspectionStepHeaderData)?.part?.documentList ?? []) ?? []),
    ...(char?.documentList ?? []),
    ...(history?.flatMap(entry => entry.attachments) ?? []),
    ...(inputtedDocs ?? []),
  ],
);

export const getSearchIsIdCheckOk = createSelector(getSearchProcessDiagram, diagram => diagram?.steps.find(({ type }) => type === StepType.IDENT_INSPECTION)?.isOk);

export const getIdentityCheckStep = createSelector(getSearchProcessDiagram, diagram => diagram?.steps.find(({ type }) => type === StepType.IDENT_INSPECTION));

export const getInspectionDecisionStep = createSelector(getSearchProcessDiagram, diagram => diagram?.steps.find(({ type }) => type === StepType.INSPECTION_DECISION));

export const getCanMakeInspectionDecision = createSelector(getSearchProcessInstanceDash, getInspectionDecisionStep, (instance, decisionStep) => {
  if (!instance || !decisionStep) return false;
  return [InspectionStatus.FINISHED, InspectionStatus.NOT_ACCEPTED, InspectionStatus.NO_DECISION, InspectionStatus.ACCEPTED].includes(instance.status);
});

export const getSearchIndexInSampleHistoryEntry = createSelector(
  getSearchCharacteristicHistory,
  getSearchCharacteristicHistoryLoading,
  getSearchActiveSampleNumber,
  getSearchIndexInSampleSelected,
  (charHistory, loadingHistory, sampleNumber, indexInSample) => {
    if (!charHistory || loadingHistory) return null;
    return charHistory.find(entry => entry.sampleNumber === sampleNumber && entry.indexInSample === indexInSample);
  },
);

export const getSearchCharacteristicIndexInSample = createSelector(getSearchCharacteristicDash, getSearchIndexInSampleSelected, (char, indexInSample) => {
  if (char?.sampleData == null) return 1;
  return indexInSample ?? char.sampleData?.indexInSample + 1;
});

export const getSearchProgress = createSelector(getSearchCharacteristicDash, getSearchStep, getSearchProcessDiagram, (ch, step, diagram) => {
  if (ch?.sampleData?.progress) return ch?.sampleData?.progress;

  if (step?.type === StepType.IDENT_INSPECTION) {
    return step.isOk ? ({ inputted: 1, planned: 1, badInputted: 0 } as Progress) : ({ inputted: 0, planned: 1, badInputted: 0 } as Progress);
  }
  if (step?.type === StepType.INSPECTION_DECISION) {
    return step.isOk ? ({ inputted: 1, planned: 1, badInputted: 0 } as Progress) : ({ inputted: 0, planned: 1, badInputted: 0 } as Progress);
  }
  if (step) return null;

  return diagram?.processInstanceData?.progress;
});

export const getSearchHasSelectedSample = createSelector(
  getSearchIndexInSampleSelected,
  getSearchCharacteristicHistory,
  (indexInSample, charHistory) => indexInSample != null && indexInSample !== (charHistory?.[0]?.indexInSample ?? 0) + 1,
);

export const getSearchStepHeaderData = createSelector(getSearchStep, step => step?.headerData);
export const getDelieveredAmount = createSelector(getSearchStepHeaderData, headerData => (headerData as InspectionStepHeaderData)?.deliveredAmount);
export const getSampleRequirements = createSelector(getSearchStepHeaderData, headerData => (headerData as InspectionStepHeaderData)?.sampleRequirements);
export const getPartRequirements = createSelector(getSearchStepHeaderData, headerData => (headerData as InspectionStepHeaderData)?.partRequirements);

export const getSerialNumberRequired = createSelector(getSearchStepHeaderData, headerData => {
  return (headerData as InspectionStepHeaderData)?.partRequirements?.[PartRequiredPropertyKeyType.SERIAL] != null;
});

export const getSearchDashHasSelectedParameter = createSelector(getSearchCharacteristicDash, param => !!param);

export const getSearchCharacteristics = createSelector(getSearchProcessDiagram, getInstanceCharacteristicSampleData, (diagram, instanceSampleData) =>
  diagram?.steps?.flatMap(({ characteristics }) =>
    characteristics.map(char => ({
      ...char,
      sampleData: instanceSampleData?.[char.id],
    })),
  ),
);

export const getSampleCharacteristics = createSelector(getSearchCharacteristics, getSearchActiveSampleNumber, (characteristics, sampleNumber) => {
  return characteristics?.filter(ch => !ch?.inspectionInterval?.everyNthSample || (sampleNumber - 1) % ch?.inspectionInterval?.everyNthSample === 0);
});

export const getIsStepSampleCompleted = createSelector(getInstanceCharacteristicSampleData, sampleData => {
  return Object.values(sampleData).every(
    sd => sd.status === CharacteristicStatus.OK || sd.status === CharacteristicStatus.NOK || sd.status === CharacteristicStatus.EXCEPTION,
  );
});

export const getSearchSelectedCharacteristicDocuments = createSelector(getSearchCharacteristicDash, characteristic => characteristic?.documentList ?? []);

export const getSearchCharacteristicCompleted = createSelector(
  getSearchCharacteristicDash,
  getInstanceCharacteristicSampleData,
  getSearchCharacteristicIndexInSample,
  (characteristic, instanceSampleData, indexInSample) => {
    return (
      characteristic == null || 
      indexInSample == null  ||
      (instanceSampleData[characteristic?.id] != null && (instanceSampleData[characteristic?.id]?.progress?.planned === 0 || instanceSampleData[characteristic?.id]?.progress?.planned < indexInSample)) ||
      (instanceSampleData[characteristic?.id] == null && characteristic.sampleSize === 0)
    );
  },
);

export const getSearchCanSubmitMessage = createSelector(
  getSearchCharacteristicHistoryLoading,
  getSearchValueMessageSubmitting,
  getSearchCharacteristicCompleted,
  getSearchCanInputCurrentStep,
  getLastSubmittedSerialNumber,
  getSerialNumberRequired,
  getSearchIndexInSampleHistoryEntry,
  (loadingHistory, isSubmitting, isCompleted, canInputStep, lastSubmittedSerialNumber, serialNumberRequired, selectedEntry) => {
    return !loadingHistory && !isSubmitting && !isCompleted && canInputStep && (!serialNumberRequired || serialNumberRequired || selectedEntry != null);
  },
);

export const getSearchBlockBorderCharacteristicIds = createSelector(getSearchCharacteristics, findBlockEndingCharacteristics);

export const getCharacteristicIdsInBlock = createSelector(getSearchCharacteristics, getSearchCharacteristicDash, (characteristics, selectedChar) => {
  if (selectedChar == null) return;
  return detectBinaryBlockCharacteristics(characteristics, selectedChar.id).map(({ id }) => id);
});

export const canChangeIncomingInspectionFlowDashboard = createSelector(
  getSearchProcessInstanceDash,
  getDashboardSelectedId,
  getParentDashboard,
  (inst, selectedId, pd) => {
    if (!pd || ![DashboardFlowType.INCOMING_INSPECTION, DashboardFlowType.OUTGOING_INSPECTION, DashboardFlowType.PRODUCTION_INSPECTION].includes(pd.flowType)) {
      return null;
    }

    if (inst == null || inst.status == null) {
      return pd.flow?.['NO_SELECTION'] != null && pd.flow['NO_SELECTION'] != selectedId;
    }
    if (inst.status === InspectionStatus.NEW || inst.status === InspectionStatus.CANCELED) {
      return pd.flow?.['NEW'] != null && pd.flow?.['NEW'] != selectedId;
    }
    if (inst.status === InspectionStatus.IN_PROGRESS) {
      return pd.flow?.['IN_PROGRESS'] != null && pd.flow['IN_PROGRESS'] != selectedId;
    }
    if (inst.status === InspectionStatus.FINISHED) {
      return pd.flow?.['FINISHED'] != null && pd.flow['FINISHED'] != selectedId;
    }

    return (pd.flow?.['DECIDED'] != null && selectedId !== pd.flow['DECIDED']) || (pd.flow['NO_SELECTION'] != null && selectedId !== pd.flow['NO_SELECTION']);
  },
);

export const canChangeSpcFlowDashboard = createSelector(
  getSearchProcessInstanceDash,
  getDashboardSelectedId,
  getParentDashboard,
  getIsStepSampleCompleted,
  (inst, selectedId, pd, isSampleDone) => {
    if (pd?.flowType !== DashboardFlowType.SPC) return false;

    if (inst == null || inst.status == null) {
      return pd.flow?.['NO_SELECTION'] != null && pd.flow['NO_SELECTION'] != selectedId;
    }
    if (inst.status === InspectionStatus.NEW) {
      return pd.flow?.['IN_PROGRESS'] != null && pd.flow['IN_PROGRESS'] != selectedId;
    }
    if (inst.status === InspectionStatus.IN_PROGRESS && !isSampleDone) {
      return pd.flow?.['IN_PROGRESS'] != null && pd.flow['IN_PROGRESS'] != selectedId;
    }
    if (inst.status === InspectionStatus.IN_PROGRESS && isSampleDone) {
      if (pd.flow['NO_SELECTION'] == selectedId) return pd.flow?.['IN_PROGRESS'] != null;
      return pd.flow?.['NO_SELECTION'] != null && pd.flow['NO_SELECTION'] != selectedId;
    }
    if (inst.status === InspectionStatus.FINISHED) {
      return pd.flow?.['FINISHED'] != null && pd.flow['FINISHED'] != selectedId;
    }

    return (pd.flow?.['DECIDED'] != null && selectedId !== pd.flow['DECIDED']) || (pd.flow['NO_SELECTION'] != null && selectedId !== pd.flow['NO_SELECTION']);
  },
);

export const getAllCharacteristicValuesInputted = createSelector(getSearchCharacteristicDash, char => {
  return char?.sampleData?.progress.planned <= char?.sampleData?.progress.inputted;
});

export const returnToNoSelectionDashboard = createSelector(getSearchProcessInstanceDash, getParentDashboard, getDashboardSelectedId, (inst, pd, selectedId) => {
  if (inst?.status !== InspectionStatus.ACCEPTED && inst?.status !== InspectionStatus.NOT_ACCEPTED && inst?.status !== InspectionStatus.NO_DECISION) {
    return false;
  }
  return pd.flow['DECIDED'] == null || selectedId === pd.flow['DECIDED'];
});

export const canChangeDashboardFlow = createSelector(
  canChangeIncomingInspectionFlowDashboard,
  canChangeSpcFlowDashboard,
  (incomingInspectionFlow, spcFlow) => incomingInspectionFlow || spcFlow,
);

export const getNextInspectionFlowDashboard = createSelector(getSearchProcessInstanceDash, getParentDashboard, getDashboardSelectedId, (inst, pd, selectedId) => {
  if (!pd || ![DashboardFlowType.INCOMING_INSPECTION, DashboardFlowType.OUTGOING_INSPECTION, DashboardFlowType.PRODUCTION_INSPECTION].includes(pd.flowType)) {
    return null;
  }

  const flow = pd.flow;
  if (!flow) return null;

  if (!inst?.status) return flow['NO_SELECTION'];

  switch (inst.status) {
    case InspectionStatus.NEW:
    case InspectionStatus.CANCELED:
      return flow['NEW'] ?? flow['IN_PROGRESS'];
    case InspectionStatus.IN_PROGRESS:
      return flow['IN_PROGRESS'] ?? flow['FINISHED'];
    case InspectionStatus.FINISHED:
      return flow['FINISHED'] ?? flow['DECIDED'];
    default:
      return flow['DECIDED'] == null || selectedId === flow['DECIDED'] ? flow['NO_SELECTION'] : flow['DECIDED'];
  }
});

export const getNextSpcFlowDashboard = createSelector(
  getSearchProcessInstanceDash,
  getParentDashboard,
  getDashboardSelectedId,
  getIsStepSampleCompleted,
  (inst, pd, selectedId, isSampleDone) => {
    if (pd?.flowType !== DashboardFlowType.SPC) return null;

    if (inst == null || inst.status == null) return pd.flow?.['NO_SELECTION'];
    if (inst.status === InspectionStatus.NEW || inst.status === InspectionStatus.CANCELED) {
      return pd.flow['IN_PROGRESS'];
    }
    if (inst.status === InspectionStatus.IN_PROGRESS && !isSampleDone) return pd.flow['IN_PROGRESS'];
    if (inst.status === InspectionStatus.IN_PROGRESS && isSampleDone) {
      if (pd.flow?.['NO_SELECTION'] === selectedId && pd.flow?.['IN_PROGRESS'] != null) return pd.flow['IN_PROGRESS'];
      return pd.flow['NO_SELECTION'];
    }
    if (inst.status === InspectionStatus.FINISHED) return pd.flow['FINISHED'];

    return pd.flow['DECIDED'] == null || selectedId === pd.flow['DECIDED'] ? pd.flow?.['NO_SELECTION'] : pd.flow?.['DECIDED'];
  },
);

export const getNextFlowDashboardId = createSelector(
  getNextInspectionFlowDashboard,
  getNextSpcFlowDashboard,
  (nextInspectionDashboard, nextSpcDashboard) => nextInspectionDashboard ?? nextSpcDashboard,
);
export const getNextFlowDashboard = createSelector(getDashboardList, getNextFlowDashboardId, (dashList, flowId) => dashList?.find(({ id }) => id === flowId));

// Dashboard Settings state
export const getDashboardSettingsState = createFeatureSelector<fromDashboardSettings.State>('dashSettings');
export const getDashSettingsList = createSelector(getDashboardSettingsState, fromDashboardSettings.getDashboardList);
export const getSettingsSelectedDashId = createSelector(getDashboardSettingsState, fromDashboardSettings.getSelectedDashboardId);
export const getDashSettingsActiveFilter = createSelector(getDashboardSettingsState, fromDashboardSettings.getActiveFilter);
export const getDashSettingsParentId = createSelector(getDashboardSettingsState, fromDashboardSettings.getParentDashboardId);
export const getDashSettingsSearchText = createSelector(getDashboardSettingsState, fromDashboardSettings.getSearchText);
export const getDashSettingsQueryParams = createSelector(getDashboardSettingsState, fromDashboardSettings.getQueryParams);
export const getDashSettingsLoading = createSelector(getDashboardSettingsState, fromDashboardSettings.getLoading);
export const getDashSettingsBreadcrumb = createSelector(getDashboardSettingsState, fromDashboardSettings.getBreadcrumb);
export const getDashSettingsRootDashboardId = createSelector(getDashboardSettingsState, fromDashboardSettings.getRootDashboardId);
//can delete if has authoroity but cannot delete if selectedDashboard is immutable or is shared
export const getSettingsSelectedDash = createSelector(getDashSettingsList, getSettingsSelectedDashId, (dashList, selectedId) =>
  (dashList ?? []).find(({ id }) => id === selectedId),
);
export const getDashSettingsEditMode = createSelector(getSettingsSelectedDash, dash => !dash || !!dash?.id);
export const getDashSettingsCanAddNew = createSelector(
  getSettingsSelectedDash,
  getDashSettingsEditMode,
  getDashSettingsActiveFilter,
  (dashboard, editMode, activeFilter) => !dashboard || (editMode && activeFilter !== 'shared'),
);
export const getDashSettingsViewMode = createSelector(() => false);
export const getDashSettingsActiveBreadcrumb = createSelector(getDashSettingsBreadcrumb, getSettingsSelectedDash, (breadcrumb, selectedDashboard) => {
  return (!selectedDashboard ? breadcrumb : [...breadcrumb, selectedDashboard]).map(({ id, title }) => ({
    id,
    name: title,
  }));
});
export const getDashCanDelete = createSelector(getSettingsSelectedDash, getActiveUserHasAnyAuthority([Authority.DASHBOARD_DELETE]), (selectedDashboard, canDelete) => {
  return canDelete && selectedDashboard?.isSystemData === false && selectedDashboard?.shared === false;
});

export const getDashCanShare = createSelector(
  getSettingsSelectedDash,
  getActiveUser,
  getActiveUserHasAnyAuthority([Authority.DASHBOARD_RELEASE]),
  (selectedDashboard, user, canShare) => canShare && selectedDashboard?.isParent && selectedDashboard?.isSystemData === false && selectedDashboard?.userId === user?.id,
);

export const getHasDashDevRights = createSelector(getActiveUserHasAnyAuthority([Authority.DEV]), canWrite => canWrite);

// File manager state
export const getFileManagerState = createFeatureSelector<fromFileManager.State>('file');
export const getFileList = createSelector(getFileManagerState, fromFileManager.getFileList);
export const getSelectedFile = createSelector(getFileManagerState, fromFileManager.getSelectedFile);
export const getFileUploadMode = createSelector(getFileManagerState, fromFileManager.getUploadMode);
export const getFileQueryParams = createSelector(getFileManagerState, fromFileManager.getQueryParams);
export const getFileItemsCount = createSelector(getFileManagerState, fromFileManager.getTotalItemsCount);
export const getFileLoading = createSelector(getFileManagerState, fromFileManager.getLoading);
export const getFileUploading = createSelector(getFileManagerState, fromFileManager.getUploading);
export const getParentDocument = createSelector(getFileManagerState, fromFileManager.getParentDocument);
export const getDocumentHistory = createSelector(getFileManagerState, fromFileManager.getDocumentHistory);
export const getDocumentHistoryEntryCount = createSelector(getFileManagerState, fromFileManager.getHistoryDocumentEntryCount);
export const getDocumentHistoryQueryParams = createSelector(getFileManagerState, fromFileManager.getHistoryQueryParams);
export const getIsEditingTextDocument = createSelector(getFileManagerState, fromFileManager.getTextEditMode);
export const getFileListSortOrder = createSelector(getFileManagerState, fromFileManager.getFileListSortOrder);
// export const geFileManagerViewOnlyMode = createSelector(getFileManagerState, fromFileManager.getViewOnlyMode);
export const getCurrentFileCustomName = createSelector(getSelectedFile, getSelectedLanguage, (selectedFile, lang) => {
  return selectedFile?.customName.translations[lang].text;
});
export const geFileManagerViewOnlyMode = createSelector(
  getActiveUserHasAnyAuthority([Authority.DOCUMENT_CREATE, Authority.DOCUMENT_UPDATE, Authority.DOCUMENT_DELETE]),
  canWrite => !canWrite,
);

export const getFileManagerSelectDialogState = createFeatureSelector<fromFMSD.State>('fmsd');
export const getFMSDFileList = createSelector(getFileManagerSelectDialogState, fromFMSD.getFileList);
export const getFMSDLoading = createSelector(getFileManagerSelectDialogState, fromFMSD.getLoading);

export const getDocumentBreadcrumb = createSelector(getSelectedFile, getParentDocument, (selectedFile, parentDocument) => {
  function buildBreadcrumb(document: DocumentStore) {
    if (!document) return [];
    return buildBreadcrumb(document.parent).concat(document);
  }

  return buildBreadcrumb(selectedFile || parentDocument);
});
export const getTextContentDocumentData = createSelector(getSelectedFile, selectedFile => ({
  id: selectedFile?.currentVersion?.id,
  name: selectedFile?.customName,
  content: selectedFile?.currentVersion.fileData.doc,
}));

// User state
export const getUserState = createFeatureSelector<fromUser.State>('u');
export const getUserList = createSelector(getUserState, fromUser.getUserList);
export const getSelectedUser = createSelector(getUserState, fromUser.getSelectedUser);
export const getUserEditMode = createSelector(getUserState, fromUser.getEditMode);
export const getUserSearchText = createSelector(getUserState, fromUser.getSearchText);
export const getUserQueryParams = createSelector(getUserState, fromUser.getQueryParams);
export const getUserLoading = createSelector(getUserState, fromUser.getUserLoading);
export const getUserManagerAuthorityList = createSelector(getUserState, fromUser.getAuthorityList);
export const getUserManagerCurrentUserUpdate = createSelector(getUserState, fromUser.getCurrentUserUpdate);
export const getUserManagerAllRoles = createSelector(getUserState, fromUser.getAllRoles);
export const getUserManagerViewOnly = createSelector(
  getActiveUserHasAnyAuthority([Authority.USER_CREATE, Authority.USER_UPDATE, Authority.USER_DELETE]),
  canWrite => !canWrite,
);

// Catalog state
export const getCatalogState = createFeatureSelector<fromCat.State>('cat');
export const getCatalogLibraryItem = createSelector(getCatalogState, fromCat.getCatLibItem);
export const getCatalogList = createSelector(getCatalogState, fromCat.getCatList);
export const getCatalogSelectedCatalog = createSelector(getCatalogState, fromCat.getSelectedCat);
export const getCatalogRootCatalog = createSelector(getCatalogState, fromCat.getRootCat);
export const getCatalogEditMode = createSelector(getCatalogState, fromCat.getEditMode);
export const getCatalogSearchText = createSelector(getCatalogState, fromCat.getSearchText);
export const getCatalogQueryParams = createSelector(getCatalogState, fromCat.getQueryParams);
export const getCatalogDisplayMode = createSelector(getCatalogState, fromCat.getDisplayMode);
export const getCatalogLoading = createSelector(getCatalogState, fromCat.getLoading);
export const getCatalogSortParam = createSelector(getCatalogState, fromCat.getSortParam);
export const getCatalogSortedQueryParams = createSelector(getCatalogQueryParams, getCatalogSortParam, (queryParams, sort) => ({ ...queryParams, sort }));
export const getCatalogSortParams = createSelector(getCatalogLibraryItem, libItem => libItem && findSortProperty(libItem));
export const getCatalogViewOnlyMode = createSelector(
  getActiveUserHasAnyAuthority([Authority.CATALOG_CREATE, Authority.CATALOG_UPDATE, Authority.CATALOG_DELETE]),
  canWriteCatalog => !canWriteCatalog,
);

export const getCatalogMetaData = createSelector(getCatalogLibraryItem, getActiveUserLicenseDetails, (libItem, licenseDetails) => {
  if (!libItem || !licenseDetails) return null;

  const updatedLibItem = cloneDeep(libItem);
  //license check for boxType
  if (updatedLibItem.componentType && updatedLibItem.propertyPages) {
    updatedLibItem.propertyPages.forEach((page: any) => {
      if (page.properties) {
        page.properties.forEach((property: any) => {
          if (property.key === 'boxTemplate') {
            property.options = property.options?.map((option: any) => {
              const feature = licenseDetails?.license?.features?.bridge?.boxType[option.key];
              return { ...option, disabled: !feature };
            });
          }
        });
      }
    });
  }

  return updatedLibItem;
});

// Catalog dialog state
export const getCatalogDialogState = createFeatureSelector<fromCatDialog.State>('catDialog');
export const getCatalogDialogSelectedCat = createSelector(getCatalogDialogState, fromCatDialog.getSelectedCatalogDialog);
export const getCatalogDialogList = createSelector(getCatalogDialogState, fromCatDialog.getCatalogList);
export const getCatalogDialogLoading = createSelector(getCatalogDialogState, fromCatDialog.getCatalogDialogLoading);
export const getCatalogDialogSearchText = createSelector(getCatalogDialogState, fromCatDialog.getCatalogDialogSearchText);
export const getCatalogDialogLibItem = createSelector(getCatalogDialogState, fromCatDialog.getCatalogDialogLibItem);
export const getCatalogDialogRoot = createSelector(getCatalogDialogState, fromCatDialog.getRootCatalogDialog);
export const getCatalogDialogSortParam = createSelector(getCatalogDialogState, fromCatDialog.getCatalogDialogSortParam);
export const getCatalogDialogQueryParams = createSelector(getCatalogDialogState, fromCatDialog.getCatalogDialogQueryParams);
export const getCatalogDialogSortParams = createSelector(getCatalogDialogLibItem, libItem => libItem && findSortProperty(libItem));
export const getCatalogDialogSortedQueryParams = createSelector(getCatalogDialogQueryParams, getCatalogDialogSortParam, (queryParams, sort) => ({
  ...queryParams,
  sort,
}));

// Role state
export const getRoleState = createFeatureSelector<fromRole.State>('role');
export const getAllRoles = createSelector(getRoleState, fromRole.getAllRoles);
export const getSelectedRole = createSelector(getRoleState, fromRole.getSelectedRole);
export const getSelectedRights = createSelector(getRoleState, fromRole.getSelectedRights);
export const getSelectedRightsParentKey = createSelector(getRoleState, fromRole.getSelectedRightsParentKey);
export const getBreadcrumb = createSelector(getRoleState, fromRole.getBreadcrumb);
export const getRoleEditMode = createSelector(getRoleState, fromRole.getEditMode);
export const getRoleTotalItemsCount = createSelector(getRoleState, fromRole.getTotalItemsCount);
export const getRoleQueryParams = createSelector(getRoleState, fromRole.getQueryParams);
export const getRoleLoading = createSelector(getRoleState, fromRole.getLoading);
export const getAllPermissions = createSelector(getRoleState, fromRole.getAllPermissions);
export const getRoleViewOnly = createSelector(getActiveUserHasAnyAuthority([Authority.ROLE_CREATE, Authority.ROLE_UPDATE, Authority.ROLE_DELETE]), canWrite => !canWrite);

//Inspection Equipment (PMV Module)
export const getInspectionEquipmentState = createFeatureSelector<fromInspEquipment.State>('inspectionEquipment');
export const getInspectionEquipments = createSelector(getInspectionEquipmentState, fromInspEquipment.getInspectionEquipments);
export const getInspectionEquipmentsSelectedId = createSelector(getInspectionEquipmentState, fromInspEquipment.getSelectedInspectionEquipmentId);
export const getInspectionEquipmentsLoading = createSelector(getInspectionEquipmentState, fromInspEquipment.getInspectionEquipmentsLoading);
export const getInspectionEquipmentBeingCreated = createSelector(getInspectionEquipmentState, fromInspEquipment.getInspectionEquipmentBeingCreated);
export const getInspectionEquipmentsPage = createSelector(getInspectionEquipmentState, fromInspEquipment.getInspectionEquipmentsPage);
export const getSelectedInspectionEquipment = createSelector(
  getInspectionEquipmentBeingCreated,
  getInspectionEquipments,
  getInspectionEquipmentsSelectedId,
  (creatingInspectionEquipment, inspectionEquipments, inspectionEquipmentId) =>
    creatingInspectionEquipment ?? inspectionEquipments.find(inspectionEquipment => inspectionEquipment.id === inspectionEquipmentId),
);
export const getInspectionEquipmentDisplayMode = createSelector(getInspectionEquipmentState, fromInspEquipment.getInspectionEquipmentDisplayMode);
export const getInspectionEquipmentViewOnly = createSelector(
  getActiveUserHasAnyAuthority([Authority.INSPECTION_EQUIPMENT_CREATE, Authority.INSPECTION_EQUIPMENT_DELETE, Authority.INSPECTION_EQUIPMENT_UPDATE]),
  canWriteCatalog => !canWriteCatalog,
);

export const getCharacteristicInspectionEquipmentIdWithBridge = createSelector(getSearchCharacteristicDash, characteristic => {
  return (characteristic?.metadata as InspectionCharacteristicMetadata)?.characteristicInspectionEquipments?.find(({ bridge }) => bridge != null)?.inspectionEquipmentId;
});
