export class CatalogCriteria {
  constructor(
    public id?: string,
    public name?: string,
    public searchText?: string,
    public description?: string,
    public type?: string,
    public parent?: string,
    public hasChildren?: boolean,
    public external?: boolean,
    public externalAPI?: string,
    public ignoreParent?: boolean,
    public rootCatalog?: string,
  ) {}
}
