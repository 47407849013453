import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { SERVER_API_URL } from '../../../../../app.constants';
import { createRequestOption } from '../../../../../shared';
import { Catalog } from './catalog.model';
import { CatalogCriteria } from './catalog.criteria';
import { IRestResponse, RestResponse, IRestError } from 'app/core/models/rest.model';
import { RestUtils } from 'app/core/utils/rest.utils';

/** defines methods used by multiple module components as well as common data and utility */
@Injectable({
  providedIn: 'root',
})
export class CatalogService {
  private resourceUrl = SERVER_API_URL + 'api/catalog-module/catalog';

  constructor(private http: HttpClient) {}

  /**
   * Creates new catalog in database.
   */
  create(catalog: Catalog): Observable<any> {
    const copy = this.convert(catalog);
    return this.http.post(this.resourceUrl, copy, { observe: 'response' }).pipe(
      map((res) => {
        const response: IRestResponse = new RestResponse();
        response.totalItems = 1;
        response.data = this.convertItemFromServer(res.body);
        return response;
      }),
      catchError((err) => {
        const error: IRestError = RestUtils.formRestErrorObject(err);
        return throwError(error);
      }),
    );
  }

  findDataSourceForInspectionEquipment(inspectionEquipmentId: string): Observable<Catalog> {
    return this.http.get<Catalog>('/api/bridge/data-source', {
      params: { inspectionEquipmentId },
    });
  }

  /** Updates existing catalog in the database. */
  update(catalog: Catalog): Observable<any> {
    const copy = this.convert(catalog);
    return this.http.put(this.resourceUrl, copy).pipe(
      map((res) => {
        const response: IRestResponse = new RestResponse();
        response.totalItems = 1;
        response.data = this.convertItemFromServer(res);
        return response;
      }),
      catchError((err) => {
        const error: IRestError = RestUtils.formRestErrorObject(err);
        return throwError(error);
      }),
    );
  }

  /** Seaches for catalog in the database by id field */
  find(id: string): Observable<IRestResponse<Catalog>> {
    if (!id || id === '') return of(null);
    return this.http.get(`${this.resourceUrl}/${id}`).pipe(
      map((res) => {
        const response = new RestResponse();
        response.totalItems = 1;
        response.data = this.convertItemFromServer(res);
        return response;
      }),
      catchError((err) => {
        const error: IRestError = RestUtils.formRestErrorObject(err);
        return throwError(error);
      }),
    );
  }

  /**
   * Searches for children of catalog item in the database
   */
  queryChildren(req?: any, catalogId?: string, libItem?: any): Observable<any> {
    const criteria = new CatalogCriteria();
    criteria.parent = catalogId;
    if (libItem && libItem.external) {
      criteria.external = true;
    } else {
      criteria.external = false;
    }
    const reqParams = createRequestOption(req);
    reqParams.append('sort', 'hasChildren,desc');

    return this.http
      .post(this.resourceUrl + '/filter/children', criteria, {
        observe: 'response',
        params: reqParams,
      })
      .pipe(
        map((res) => {
          const result: any = res.body;

          const response: IRestResponse = new RestResponse();
          response.totalItems = +res.headers.get('X-Total-Count');
          response.data = result.map((catalog) => this.convertItemFromServer(catalog));

          return response;
        }),
        catchError((err) => {
          const error: IRestError = RestUtils.formRestErrorObject(err);
          return throwError(error);
        }),
      );
  }

  queryFlat(req?: any, criteria?: Partial<CatalogCriteria>): Observable<Catalog[]> {
    const params = createRequestOption({
      ...req,
      sort: ['hasChildren,desc', ...[req['sort'] || []]],
    });

    return this.http.post<Catalog[]>(this.resourceUrl + '/filter/flat', criteria, { params });
  }

  /**
   * Query a catalog from database by any criteria
   */
  query(req?: any, searchText?: any, rootCatalog?: Catalog | string, type?: string): Observable<any> {
    const criteria = new CatalogCriteria();
    criteria.parent = rootCatalog ? (typeof rootCatalog === 'string' ? rootCatalog : rootCatalog.id) : null;
    criteria.type = type;
    criteria.name = searchText;

    const reqParams = createRequestOption({
      ...req,
      sort: ['hasChildren,desc', ...[req['sort'] || []]],
    });

    return this.http
      .post(this.resourceUrl + '/filter', criteria, {
        observe: 'response',
        params: reqParams,
      })
      .pipe(
        map((res) => {
          const result: any = res.body;

          const response: IRestResponse = new RestResponse();
          response.totalItems = +res.headers.get('X-Total-Count');
          response.data = result.map((catalog) => this.convertItemFromServer(catalog));

          return response;
        }),
        catchError((err) => {
          const error: IRestError = RestUtils.formRestErrorObject(err);
          return throwError(error);
        }),
      );
  }
  /** Deletes a catolg item from the database by id propertie */
  delete(id: string): Observable<any> {
    return this.http.delete(`${this.resourceUrl}/${id}`).pipe(
      map(() => {
        const response: IRestResponse = new RestResponse();
        return response;
      }),
      catchError((err) => {
        const error: IRestError = RestUtils.formRestErrorObject(err);
        return throwError(error);
      }),
    );
  }

  //find catalogs by ids
  findCatalogsByIds(ids: string[]): Observable<Catalog[]> {
    return this.http.post<Catalog[]>(`${this.resourceUrl}/filter/ids`, ids);
  }

  changeFolder(changeData: { targetFolderId: string; catalogId: string }) {
    return this.http.post(this.resourceUrl + '/change-folder', changeData).pipe(
      map((res) => {
        const response: IRestResponse = new RestResponse();
        response.totalItems = 1;
        response.data = this.convertItemFromServer(res);
        return response;
      }),
      catchError((err) => {
        const error: IRestError = RestUtils.formRestErrorObject(err);
        return throwError(error);
      }),
    );
  }

  /**
   * Convert a returned JSON object to Catalog.
   */
  private convertItemFromServer(json: any): Catalog {
    return { ...new Catalog(), ...json };
  }

  /**
   * Does shallow copy and removes children.
   */
  private convert(catalog: Catalog): Catalog {
    return {
      ...catalog,
      children: [],
    };
  }
}
